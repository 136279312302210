import React from 'react'
import "../legal.scss";

const CompanionCautionNotice = () => {
  return (
    <div className='legal-cover'>
      {/* <Header /> */}
      <div className="legal-content">
        <h1 className="visuallyHidden">Caution Notice</h1>
        <h2 className="heading monument mb-10 mt-20">CAUTION NOTICE</h2>
        <div className="sec-wrapper">
          
          <p className="small">
          This is to notify the customers and the public at large that certain unidentified persons are attempting to perpetrate a fraud on our customers/ the public by creating email accounts that are deceptively similar to ours and using the same to send unauthorised and fraudulent communications posing as authorised representatives of Matter and also seeking payments towards pre-booing/booking/down-payment. Moreover, they are deliberately misusing and infringing upon the our name, trademark, domain name, and logo, with the clear intent to tarnish the our image and reputation for their own illegitimate gains. It is currently unclear if the fraudsters are also approaching the public through other means.
          </p>
          <p className="small">The public in general are also advised to remain vigilant and not to fall victim to such fraudulent activities or misrepresentations. We cannot be held responsible and liable for any loss or damage (direct or indirect) suffered by any one due to use of or dealing with such fraudsters impersonating to be representing Matter.</p>
          <p className="small">If you come across any such fraudulent incidents or have any information about persons involved in such fraudulent activities, we kindly request your cooperation in helping us take necessary action to prevent and combat these wrongful and unlawful actions. You may reach out to us on <a target="_blank" className='white bold' href="tel:18002035630">1800-203-5630</a> or send an email to <a target="_blank" className='white bold' href='mailto:customer.care@matter.in'>customer.care@matter.in</a> in this regard.</p>
          <p className="small">Persons who engage in or attempt unscrupulous activities are hereby warned that they will face strict legal action under the Indian Penal Code, 1860, the Information Technology Act, 2000, and other applicable laws, whether civil or criminal. This legal action will be taken for deceiving, cheating, fraud, misinterpretation, forgery, and other such activities that deceive the public at large and infringe upon or violate our intellectual property rights, as well as tarnish our reputation and goodwill.</p>
          <p className="small">
            <b>Last Updated On</b><br/>
            4th August 2023
          </p>
        </div>

        
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default CompanionCautionNotice