export const ActionTypes = {
    SET_PINCODE: 'SET_PINCODE',
    SET_FLOW: 'SET_FLOW',
  }
  
  export const setPincode = (pincode: string) => ({
    type: ActionTypes.SET_PINCODE,
    payload: pincode,
  });

  export const setFlow = (isBookNow: boolean) => ({
    type: ActionTypes.SET_FLOW,
    payload: isBookNow,
  });
  
