import { applyMiddleware, createStore, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import RootReducer from '../reducers'
import appMiddleware from './middleware'

// import middleware from './middleware'

//= =======================================
// reducer & PersistentReducer
//= =======================================
const reducer = persistReducer(
  {
    key: '@pp-6u!ld3r', // key is required
    storage: storage, // storage is now required (using localStorage)
    whitelist: ['home', 'userDetails', 'createPreBooking',"partnerAndChannel","registerYourInterest"], // only this list will not be persisted
    blacklist: [], // list will not be persisted
    stateReconciler: autoMergeLevel2,
  },
  RootReducer
)

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

// ======================================================
// Store Enhancers
// ======================================================
const enhancers: any = []

let composeEnhancers = compose

const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
if (typeof composeWithDevToolsExtension === 'function') {
  composeEnhancers = composeWithDevToolsExtension
}

// ======================================================
// Store Instantiation and HMR Setup
// ======================================================

const logger = (store: any) => (next: any) => (action: any) => {
  let result
  console.groupCollapsed('dispatching', action.type)
  console.log('prev state: ', store.getState())
  console.log('action: ', action)
  result = next(action)
  console.log('next state: ', store.getState())
  console.groupEnd()
  return result
}

const configStore = (initialState: any = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...appMiddleware), ...enhancers)
  )

  return {
    persistor: persistStore(store),
    store,
  }
}

const { store, persistor } = configStore()

export { store, persistor }
