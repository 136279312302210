import './support.scss'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Icons from '../../utils/icons'
import Images from '../../utils/images'
import { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useHistory } from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import MarkDown from '../../components/Markdown'
import homeActions from '../../actions/homeActions'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerModel } from '../../reducers/reducerModel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {ClickAwayListener} from '@mui/base'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { tagMethodGTM } from '../../utils/common'
import HeadlessComponent from '../../components/HeadlessComponent'

const CompanionAppSupport = () => {
  const dispatch: any = useDispatch()
  const { contactUs, trendingFAQ, faqAppliedSearch } = useSelector(
    (store: ReducerModel) => store.home
  )
  const [searchFAQ, setSearchFAQ] = useState('')
  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const history = useHistory()
  useEffect(() => {
    console.log('Support')
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
    dispatch(homeActions.resetFAQ())
    dispatch(homeActions.getBannerStrapiApi())
    dispatch(homeActions.getContactUsStrapiApi())
    
    dispatch(
      homeActions.getFaqCategoriesStrapiApi(
        () => {},
        () => {}
      )
    )
    dispatch(
      homeActions.getMasterChildFAQ(
        () => {},
        () => {}
      )
    )
  }, [])


  const handleExpendChange =
  (itemId: number) => (event: any, newExpanded: boolean) => {
    setSelectedQuestion(selectedQuestion == itemId ? -1 : itemId)
  }

  return (
    <div className="support-page-wrapper">
      {/* <Header /> */}
    <h1 className="visuallyHidden">Matter Support</h1>
      <HeadlessComponent
        title="Reach Out for Support: Get Expert Assistance| MATTER"
        conicalLink="https://www.matter.in/support"
        description="Need help with your MATTER electric bike? Visit our support page for FAQs, troubleshooting tips, and expert assistance. One-stop shop for all your answers."
      />
      <section className="support-banner">
        <img
          className="banner-image for-desktop"
          src={Images.SupportBanner}
          alt="Matter Support"
        />
        <img
          className="banner-image for-mob"
          src={Images.SupportBannerMobile}
          alt="Matter Support"
        />
        <div className="banner-content">
          <h2 className="monument primary">Matter Support</h2>
          <h3 className="monument white mb-16">
            Your hub for EV assistance, <br />
            answers, and advice.
          </h3>
          <p className="subTextLight">
            Get the answers and support you need for your electric vehicle
            journey. We're here for you!
          </p>
          <ClickAwayListener
            onClickAway={() => {
              document
                .querySelector('.faq-search-list')
                ?.classList.remove('show')
            }}
          >
            <div className="ask-cover">
              <input
                value={searchFAQ}
                onKeyUp={(onKeyUpEvent) => {
                  if (onKeyUpEvent.keyCode == 13) {
                    history.push('/faq-app', { search: searchFAQ })
                  }
                }}
                onChange={(event) => {
                  event.preventDefault()
                  const value = event.target.value
                  setSearchFAQ(event.target.value)
                  if (value.length == 0 || value.length >= 3) {
                    document
                      .querySelector('.faq-search-list')
                      ?.classList.add('show')
                    dispatch(
                      homeActions.getFAQAppliedSearch(
                        event.target.value,
                        () => {},
                        () => {}
                      )
                    )
                  }
                  if (value.length == 0) {
                    document
                      .querySelector('.faq-search-list')
                      ?.classList.remove('show')
                  }
                }}
                placeholder="Ask away!"
                onBlurCapture={() => {
                  tagMethodGTM(
                    `Ask Away`,
                    `Matter Ask Away`,
                    'Click',
                    `${searchFAQ}`
                  )
                }}
                type="text"
              />
              <img src={Icons.SearchGrayIcon} />

              <ul className="faq-search-list">
                {faqAppliedSearch.faq_maters.map((item) => {
                  const makeBold = () => {
                    var reg = new RegExp(searchFAQ, 'gi')
                    var final_str = item.question.replace(reg, function (str) {
                      return '<b>' + str + '</b>'
                    })
                    return final_str
                  }

                  return (
                    <li
                      onClick={() => {
                        history.push('/faq-app', { faqQuestion: item })
                        console.log('item ', item)
                        tagMethodGTM(
                          `Ask Away`,
                          `Matter Ask Away`,
                          'Click',
                          `${item?.question}`
                        )
                      }}
                      key={item.id}
                    >
                      <p
                        dangerouslySetInnerHTML={{ __html: makeBold() }}
                        className="small"
                      ></p>
                    </li>
                  )
                })}

                {!faqAppliedSearch.faq_maters.length && searchFAQ && (
                  <li>
                    <p className="small">No matches found</p>
                  </li>
                )}

                {faqAppliedSearch.faq_matersCount &&
                faqAppliedSearch.faq_matersCount > 0 ? (
                  <li className="right">
                    <a
                      onClick={() => {
                        tagMethodGTM(
                          `Ask Away`,
                          `Matter Ask Away`,
                          'Click',
                          `View all Results ${faqAppliedSearch.faq_matersCount}`
                        )
                        history.push('/faq-app', { search: searchFAQ })
                      }}
                      className="underline primary"
                    >
                      View all {faqAppliedSearch.faq_matersCount} Results
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
          </ClickAwayListener>
        </div>
      </section>
      <section className="support-content content-container">
        <div className="flex al-center js-between">
          <h4 className="monument mb-16 mb-xs-8">Trending FAQ’s</h4>
          <Link
            className="primary mb-16 mb-xs-8 view-all-desktop bold"
            href="/faq-app"
            onClick={() =>
              tagMethodGTM(
                `Trending FAQs`,
                `Matter Trending FAQs`,
                'Click',
                `View All FAQs`
              )
            }
          >
            View all FAQs &nbsp;
            <i className="arrow-blue-sm-icon"></i>
          </Link>
        </div>
        <div className="faq-list">
          {trendingFAQ.map((item) => {
            return (
              <Accordion
              expanded={selectedQuestion == item.id}
              onChange={handleExpendChange(item.id)}
                key={item.id} disableGutters elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  
                  id="panel1a-header"
                  onClick={() =>
                    tagMethodGTM(
                      `Trending FAQs`,
                      `Matter Trending FAQs`,
                      'Click',
                      `${item.question}-${selectedQuestion == item.id ? 'Collapse':'Expand'}`
                    )
                  }
                >
                  <p className='medium'>{item.question}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="subText answerText">
                    <MarkDown disableLinkClick={true} string={item.answer} />
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          })}
          <div className="right view-all-mobile">
            <Link
              className="primary mt-16 mt-xs-8 bold"
              href="/faq-app"
              onClick={() =>
                tagMethodGTM(
                  `Trending FAQs`,
                  `Matter Trending FAQs`,
                  'Click',
                  `View All FAQs`
                )
              }
            >
              View all FAQs &nbsp;
              <i className="arrow-blue-sm-icon"></i>
            </Link>
          </div>
        </div>

        <h4 className="monument mb-16 mb-xs-8">Contact Us</h4>
        <ul className="support-list">
          <li>
            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={5}>
                <h6 className="small semi-bold">Chat via Matter Flux Bot</h6>
              </Grid>
              <Grid item xs={12} sm={7}>
                <p className="mb-20 mb-xs-10">
                  <a
                    className="primary pointer"
                    onClick={() => {
                      tagMethodGTM(
                        `Contact Us`,
                        `Matter Contact Us - Chat via Matter Flux Bot`,
                        'Click',
                        `Website`
                      )

                      const zoho = window['$zoho']
                      if (zoho) {
                        // zoho.salesiq.floatwindow.open()
                      }
                    }}
                  >
                    Website
                  </a>{' '}
                  or
                </p>
                <p
                
                >
                  WhatsApp :{' '}
                  <a
                  target={'_blank'}
                    onClick={() => {
                      tagMethodGTM(
                        `Contact Us`,
                        `Matter Contact Us - Chat via Matter Flux Bot`,
                        'Click',
                        `WhatsApp`
                      )
                    }}
                    className="primary"
                    href={`https://wa.me/${contactUs.whatsApp}`}
                  >
                    {contactUs.whatsApp}
                  </a>{' '}
                </p>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={5}>
                <h6 className="small semi-bold">Call us</h6>
              </Grid>
              <Grid item xs={12} sm={7}>
                <p>
                  Toll Free Number :{' '}
                  <a 
                   onClick={() => {
                    tagMethodGTM(
                      `Contact Us`,
                      `Matter Contact Us - Call Us`,
                      'Click',
                      `Toll Free Number: ${contactUs.callUs}`
                    )
                  }}
                  className="primary medium contact-us-number" href={`tel:${contactUs.callUs}`}>
                    {contactUs.callUs}
                  </a>
                </p>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={5}>
                <h6 className="small semi-bold">Write to us</h6>
              </Grid>
              <Grid item xs={12} sm={7}>
                <p>
                  E-Mail ID :{' '}
                  <a 
                   onClick={() => {
                    tagMethodGTM(
                      `Contact Us`,
                      `Matter Contact Us - Write to us`,
                      'Click',
                      `E-Mail ID: ${contactUs.email}`
                    )
                  }}
                  className="primary" href={`mailto:${contactUs.email}`}>
                    {contactUs.email}
                  </a>{' '}
                </p>
              </Grid>
            </Grid>
          </li>
        </ul>
      </section>
      {/* <Footer /> */}
    </div>
  )
}

export default CompanionAppSupport
