export default {
  UPDATE_AVAILABLE_LOCATIONS_LIST: 'UPDATE_AVAILABLE_LOCATIONS_LIST',
  CANCEL_REASONS: "CANCEL_REASONS",
  TOP_BANNER: "TOP_BANNER",
  CONTACT_US: "CONTACT_US",
  PRODUCT_SPEC: "PRODUCT_SPEC",
  FAQ_CATEGORIES: "FAQ_CATEGORIES",
  FAQ_DETAILS: "FAQ_DETAILS",
  TRENDING_FAQ: 'TRENDING_FAQ',
  FAQ_APPLIED_SEARCH: 'FAQ_APPLIED_SEARCH',
  RESET_FAQ: 'RESET_FAQ',
  INNOVATION_HUB: 'INNOVATION_HUB',
  WHO_WE_ARE_REWARDS: "WHO_WE_ARE_REWARDS",
  WHO_WE_ARE_EVENTS: "WHO_WE_ARE_EVENTS",
  WHO_WE_ARE_DETAILS: "WHO_WE_ARE_DETAILS",
  WHO_WE_ARE_LEADER_LIST: "WHO_WE_ARE_LEADER_LIST",
  UPDATE_WEB_APP_CONFIG: "UPDATE_WEB_APP_CONFIG",
  BLOGS: "BLOGS",
  BLOG_DETAILS: "BLOG_DETAILS",
  TERM_CONDITION_JOIN_WAITLIST: 'TERM_CONDITION_JOIN_WAITLIST',
  TERMS_CONDITIONS_LEGAL_POLICY: 'TERMS_CONDITIONS_LEGAL_POLICY',
  PRIVACY_POLICY: 'PRIVACY-POLICY',
  TERM_CONDITION_ONLINE_PREBOOK: 'TERM_CONDITION_ONLINE_PREBOOK',
  HOME_PAGE_DETAILS: 'HOME_PAGE_DETAILS',
  EMI_CALCULATOR: "EMI_CALCULATOR",
  PRODUCT_PAGE_DETAILS: 'PRODUCT_PAGE_DETAILS',
  NEWS: "NEWS",
  AWARDS: "AWARDS",
  PRESS_RELEASES: "PRESS_RELEASES",
  LEADERSHIP: "LEADERSHIP",
  LOGOSANDTRADEMARK: "LOGOSANDTRADEMARK",
  AERA5000e:"AERA5000e",
  AERA5000: "AERA5000",
  AERA5000PLUS: "AERA5000PLUS",
  PRODUCTPORTFOLIO: "PRODUCTPORTFOLIO"
}
