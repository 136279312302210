interface PincodeState {
    pincode: string;
    isBookNow: boolean;
  }
  
  const initialState: PincodeState = {
    pincode: '',
    isBookNow: true,
  }
  
  export const pincodeReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'SET_PINCODE':
        return {
          ...state,
          pincode: action.payload,
        };
        case 'SET_FLOW':
          return {
            ...state,
            isBookNow: action.payload,
          };
      default:
        return state
    }
  };

  