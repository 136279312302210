import React, { useState } from 'react'
import Header from '../../../components/Header'
import "../legal.scss";
import Footer from "../../../components/Footer";
import { useDispatch } from 'react-redux';
import prebookingActions from '../../../actions/prebookingActions';
import { useHistory } from 'react-router-dom';
import Toaster from '../../../components/Toaster';
import { useEffect } from 'react';
import HeadlessComponent from '../../../components/HeadlessComponent';

const OnlinePreBookingTerms = () => {

  const dispatch:any = useDispatch()

  const history = useHistory()

  const [messageToast, setMessageToast] = useState("")

  useEffect(() => {
    document.title = 'Pre-booking Policy | Matter'
  }, [])
  const onClickPrebook = () => {
    dispatch(prebookingActions.prebookValidate((data: any) => {
      if (data.preBookEnabled) {
        history.push("/booknow")
      } else {
        setMessageToast(`PRE-BOOKING STARTS 17th May`)
      }
    }, () => { }))
    setMessageToast("")
  }




  return (
    <div className='legal-cover'>
       {messageToast && (
          <Toaster successMessage={messageToast} failMessage="" />
        )}
      {/* <Header /> */}
      <div className="legal-content">
        <h1 className="visuallyHidden">
        TERMS AND CONDITIONS OF ONLINE PRE-BOOKING
        </h1>
        <HeadlessComponent
        title="Online Pre-booking Policy | Matter"
         conicalLink="https://www.matter.in/online-prebooking-terms" 
        description="Check out the terms and conditions of online pre-booking a Matter AERA. Understand Preregistration Price, Delivery, Cancellation Policy, etc."
        />
        <h2 className="heading monument mb-40 mb-xs-30">TERMS AND CONDITIONS OF ONLINE PRE-BOOKING</h2>
        <p>
          The terms and conditions set out below (“Online Pre-Booking T&C”) explain the terms and conditions applicable to any person who Pre-Books (defined hereinafter) vehicle/s listed on the Platform (defined hereinafter) by ‘Matter Motor Works Private Limited’ and its subsidiary ‘Matter Manufacturing Private Limited’, each being a company incorporated under the Companies Act, 2013 and each having its registered office at 301, Parishram Building, 5B, Rashmi Society, Nr. Mithakhali Six Roads, Navrangpura, Ahmedabad –380009, India (each referred to as “the Company”/ “we”/ “us”, which expression, unless the context otherwise requires, shall mean and include the respective successors and assigns of each Company). The Customer must read these Online Pre-Booking T&C carefully before accessing the online Pre-Booking services available on the Platform.
        </p>
        <p>
          Additionally, the ‘Terms and Conditions of Use’ given at
          {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white' href="/terms-and-conditions">https://matter.in/terms-and-conditions</a> {' '} and the ‘Privacy Policy’ given at
          {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white' href="/privacy-policy">https://matter.in/privacy-policy</a> {' '} also apply to the Customer and are deemed to have been incorporated herein by reference, and the Customer must also review these carefully before accessing the aforesaid services through the Platform.
        </p>
        <br />

        <div className="sec-wrapper">
          <h5 className="monument mb-20">A. DEFINITIONS</h5>
          <p>
            The following capitalised terms and expressions when used in this Online Pre-Booking T&C shall have the meanings as ascribed to them hereunder:
          </p>
          <ul>
            <li>
              “Account” shall mean the profile created/registered by the Customer on the Platform.
            </li>

            <li>
              “App” shall mean the mobile application or similar application provided on other electronic devices operated by the Company under the brand name “Matter”.
            </li>

            <li>
              “Authorised Entity" shall mean any person or entity appointed by the Company for the purpose of facilitating the sale of the Matter Vehicles  and shall also include the Company  in cases where the sale of the Matter Vehicles is directly undertaken by the Company.
            </li>
            <li>
              “Customer” shall mean any prospective customer of the Company who Pre-Books a Matter Vehicle, who should be an individual/firm/proprietorship/company or other legal entity competent to enter into a contract under the Indian Contract Act, 1872.
            </li>
            <li>
              “Matter Vehicle” shall mean the vehicles listed on the “Pre-Book” section as provided on the Platform.
            </li>
            <li>
              “Platform” shall mean (i) the Website (ii) the App  and (iii) any other online interface through which the Company provides the facility to the Customers to Pre-Book the Matter Vehicles.
            </li>
            <li>
              “Pre-Book”/ “Pre-Booking”/ “Pre-Booked” shall mean the facility offered to the Customers to book Matter Vehicles listed on the Platform in advance, at a stage prior to the commercial production of the vehicles, through online medium. It being clarified that Pre-booking shall not constitute an agreement of sale of the Selected Vehicle between the Company and Customer nor does the Company guarantee to the Customers that the Selected Vehicle will be manufactured and/or delivered to them.
            </li>
            <li>
              “Pre-Booking Amount” shall have the meaning ascribed to it in paragraph B(2) below.
            </li>
            <li>
              “Purchase Price” shall have the meaning ascribed to it in paragraph C(2) below.
            </li>
            <li>
              “Selected Vehicle” shall mean the vehicle that is selected by the Customer from the Matter Vehicles listed on the “Pre-Book” section as provided on the Platform.
            </li>
            <li>
              “Website” shall mean {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white' href='https://matter.in'>https://matter.in</a> {' '} (or any other domain extension of the same) or any mobile site, operated by the Company under the brand name “Matter” and includes any successor or replacement website/mobile site.
            </li>

          </ul>
        </div>
        <div className="sec-wrapper">
          <h5 className="monument mb-20">B.	PRE-BOOKING OF SELECTED VEHICLE</h5>
          <p>
            The following capitalised terms and expressions when used in this Online Pre-Booking T&C shall have the meanings as ascribed to them hereunder:
          </p>
          <ul>
            <li>
              The Company is desirous of providing the facility to the Customers of Pre-Booking their Selected Vehicle/s through use of the Platform at their convenience. Customers are provided with the opportunity to view the Matter Vehicles on the Platform and Pre-book the Selected Vehicle.                </li>

            <li>
              For Pre-Booking the Selected Vehicle online, the Customer is required to pay an amount specified and displayed on the Platform (“Pre-Booking Amount”).</li>

            <li>
              The Pre-Booking of the Selected Vehicle may be made on the Platform under the “Pre-Book” section, after registering an Account on the Platform. 
              {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white'  href="/booknow">Click here</a> {' '} to Pre-Book. Please note that the details provided by Customers on their Account may be shared with the concerned Authorised Entity, third party vendors (including financiers, insurers, payment gateway) and/or government departments in order to process the Pre-Booking and for other purposes provided in the Privacy Policy, and the same shall be governed in accordance with our
              {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white' href="/privacy-policy">Privacy Policy</a>.
            </li>
            <li>
              Up to two Matter Vehicles shown on the Platform can be Pre-Booked under each Account registered on the Platform.
            </li>
            <li>
              In the event the Customer is  Pre-Booking on behalf of another person, the Customer shall be liable to indemnify the Company, its affiliates, its directors, employees, agents, consultants, business partners, representatives and/or the Authorised Entities against any losses or liabilities incurred by any of them in connection with such Pre-Booking.
            </li>
            <li>
              The Customer must provide complete, true and accurate information while Pre-Booking the Selected Vehicle online. The Customer hereby agrees to indemnify the Company, its affiliates, its directors, employees, agents, consultants, business partners, representatives and the Authorised Entities against any losses or liabilities incurred by any of them as a result of a breach of this covenant.
            </li>
            <li>
              The variant of the Selected Vehicle Pre-Booked by the Customers, the Pre-Booking Amount paid details etc. can be accessed by the Customer under his/her Account on the Platform.
            </li>
            <li>
            The Customer may select the relevant location in which the Customer wishes to Pre-Book the Selected Vehicle. Customers are allowed to change the location selected by them once. 
            </li>
          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">C.	PRICE OF THE MATTER VEHICLES</h5>

          <ul>
            <li>
              The prices displayed on the Platform are “Pre-Registration Price”. In cases where the Customer is eligible to take benefit of, and avails, any discount/promo code (or any similar offer) that is available on the Platform, the “Price for You” will also be displayed. The Pre-Registration Price or the Price for You (if applicable) that is displayed on the Platform is only an estimated price of the Matter Vehicle, and:
              <ul className='disc mt-20'>
                <li>has been estimated after deducting the FAME-II subsidy made available by the central government as on date of Pre-Booking, for which the Matter Vehicles may become eligible. However, please note that in the event the FAME-II subsidy is not approved in respect of the Matter Vehicles, or withdrawn/amended by the government or the FAME India Scheme (Phase -II) expires, or if the Customer is not eligible to receive the FAME-II subsidy, then the Company shall not be bound to sell the Matter Vehicles at a reduced price, and the “Pre-Registration Price” or the “Price for you” (if applicable) may be revised accordingly; </li>
                <li>does not include the cost of insurance, registration, handling, logistics or any other similar cost elements and governmental levies, if any, and </li>
                <li>is only indicative and may be subject to change at the sole discretion of the Company. </li>
                <p>The Company shall not be responsible/legally bound to inform the Customer in advance in case of a price change. </p>
              </ul>
            </li>

            <li>
              The final price payable by the Customer at the time of delivery of the Selected Vehicle shall be the “Purchase Price” which shall include the cost of insurance, registration, handling, logistics etc. The Pre-Booking Amount paid by the Customer at the time of online Pre-Booking will be adjusted against the Purchase Price of the Selected Vehicle at the time of raising of the invoice for the Selected Vehicle. The Customer will be required to pay the balance Purchase Price to the Authorised Entity to complete the purchase of the Selected Vehicle.
            </li>

            <li>
              In case the Customer has applied / will apply for finance of the Selected Vehicle through any financer (whether those who have partnered with the Company or otherwise), the Customer shall be required to submit the finance sanction letter directly to the concerned Authorised Entity.
            </li>
          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">D.	ACCEPTANCE OF THE PRE-BOOKING AMOUNT</h5>
          <ul>
            <li>
              The Customer will be required to pay the exact Pre-Booking Amount displayed on the Platform for Pre-Booking of the Selected Vehicle.
            </li>

            <li>
              The Company utilises the services of ‘Billdesk’ (“Payment Gateway”) for the purpose of accepting the payments, which can be made by Credit Card/Debit Card or Net Banking through NEFT/IMPS or UPI payment in favour of the Company. The Customer hereby authorizes the Payment Gateway to collect, facilitate, process and remit payments and/or the transaction electronically in respect of transactions through its facility.
            </li>

            <li>
              The Company reserves the right to restrict the number of online Pre-Bookings which the Customer can make through their Credit Card/Debit Card/Bank account or any other financial instrument and accordingly reserves its right to reject an online Pre-Booking made by the Customer, without assigning any reasons.
            </li>
            <li>
              Please note that the Payment Gateway is a third party service provider. Payments made by the Customer for the Pre-Booking of the Selected Vehicle through the Payment Gateway are subject to the terms and conditions as provided by the said Payment Gateway.
            </li>
            <li>
              The Company shall not be responsible for any non-payment or wrong payment or unauthorised payments made in respect of the Pre-Booking through the Payment Gateway.
            </li>
            <li>
              After a valid online payment transaction, the Customer will receive a payment confirmation and the same shall be reflected on the Platform. The Customer will also receive an Order ID which shall be used for all future communications with the Authorised Entity and will be required to provide the Order ID as and when required by the concerned Authorised Entity.
              <br />
              During an attempted Pre-Booking, if Pre-Booking Amount is deducted by the Customer’s bank but not received by the Payment Gateway, the Pre-Booking will remain pending until the payment is received by the Company. Once the Pre-Booking Amount has been received by the Payment Gateway, the Company shall notify the Customer of the same confirming the Pre-Booking and such notifications should ordinarily be received by the customer within 2 (two) working days of the date of the attempted payment. In the event the Pre-Booking Amount is not received by the Payment Gateway, the Pre-Booking initiated by the customer shall remain pending. In such a case, the Customer is advised to wait for a period of 2 (two) working days from the date of the attempted payment and then reach out to us at
              {' '} <a href='mailto:customer.grievance@matter.in'>customer.grievance@matter.in</a> {' '} 
              and raise a complaint in this regard. The Customers are requested to abstain from making the payment through the Platform for a second time if they have not received any notification with respect to the confirmation of the payment, from the Company.
            </li>
            <li>
              The Pre-Booking Amount shall be paid by the Customer and received by the Company only through the Platform, and the Company has not authorised any of its employees/representatives to approach the Customers seeking payments in any other manner. The Customers shall be solely responsible for the payments made outside the Platform.
            </li>
          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">E.	MODIFICATIONS AND CHANGES</h5>
          <p>
            If the Customers so wish, they may make changes in the colour and variant of the Selected Vehicle by clicking on the “Modify” button in their Account any time before the payment of the balance Purchase Price and no modifications/changes shall be allowed thereafter. If the new colour/variant selected by the Customer is of a higher Purchase Price than the original variant selected by the Customer, the newly selected colour/variant will be accepted only on the Customer’s confirmation that he/she shall also pay the differential amount at the time of making the payment of the balance Purchase Price of the Selected Vehicle. Please note that the selection of a new colour/variant may lead to an extension in the expected date of delivery of the Selected Vehicle.
          </p>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">F.	DELIVERY OF THE SELECTED VEHICLE</h5>
          <ul>
            <li>
              The Customer will be required to pay the exact Pre-Booking Amount displayed on the Platform for Pre-Booking of the Selected Vehicle.
            </li>

            <li>
              The delivery of the Selected Vehicle is subject to the receipt of all governmental approvals. In the event the delivery timelines are extended due to any delay in obtaining the requisite governmental approvals by the Company, the Customer will be notified of the same. In such a case, the Customer will have the option to cancel the Pre-Booking in accordance with section G (Cancellation) and the Customer will be entitled to receive the refund of the entire Pre-Booking amount paid without any interest, and the same shall be the Customer’s only recourse against the Company.
            </li>

            <li>
              Once the Selected Vehicle is ready for delivery, the Company shall intimate the Customer about the expected date of delivery along with the details pertaining to the payment of the balance Purchase Price to be made by the Customer and other relevant details. All formalities with respect to the payment of the balance Purchase Price and delivery of the Selected Vehicle, including but not limited to the registration process, shall be performed by the concerned Authorised Entity. If the Customer fails to pay the balance amount of the Purchase Price within the stipulated time as notified to him/her (or within the extended time-period, as the case may be), the Company shall be entitled to cancel the Customer’s Pre-Booking and allocate the Selected Vehicle to the next customer without any liability to the Customer except the refund of the Pre-Booking Amount paid by the Customer (without any interest) in accordance with section H of this Online Pre-Booking T&C after the cancellation. Thereafter, if the Customer wishes to purchase a Matter Vehicle, the Customer will be required to initiate the Pre-Booking process again at the then prevailing rates. The Customer may write to us seeking an extension of the time period, in which case the Company shall be entitled to extend the time period for the payment of the balance Purchase Price at its sole discretion.
            </li>

          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">G.	CANCELLATION</h5>
          <p>
            Customers may cancel the Pre-Booking of the Selected Vehicle any time after June 20, 2023 but before having made the full payment of the balance Purchase Price under their Account. However, Customers cannot initiate a cancellation request on the Platform once they have made payment of the balance Purchase Price. After the completion of the cancellation process, the Customers will receive a communication, confirming the said cancellation and the same shall be reflected on the Platform. The Company may get in touch with the Customers in case any additional details are required to process their cancellation request.
          </p>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">H.	REFUND</h5>
          <p>
            No cash payments for refund shall be made. The Pre-booking Amount paid by Customers shall be refunded through the same mode that was used by them for making the payment of the Pre-Booking Amount. The refund will be made within 30 (thirty) days from the day of request of cancellation made by the Customer without interest. It being clarified that the request of cancellation may be made by the Customer after June 20, 2023.
          </p>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">I.	INSURANCE</h5>
          <ul>
            <li>
              The contents of this section I are solely for the informational purposes only and nothing contained on the Platform shall be interpreted as legal, tax, investment, financial or any other advice. The Customers acknowledge that nothing contained on the Platform constitutes a solicitation, recommendation, endorsement or offer by the Company or the third party service provider (through our Platform) to procure an insurance.
            </li>

            <li>
              As per the Motor Vehicles Act, 1988, any person purchasing a Matter Vehicle must have a valid insurance policy. Customers are free to choose the insurance service provider at their own will. It shall be the sole responsibility of the Customer to evaluate the merits and the risks associated with the selection of the insurer.  The insurance policy shall be a separate contract between the Customer and the insurer and the Company shall not be responsible in respect of the same in any manner. The Platform may occasionally contain links to third party service providers who offer insurance policies. When the Customer clicks on the links to such third-party websites, the Customer leaves our Platform. The Customer acknowledges that the Customer shall be bound by the terms and conditions of such third party service providers. The Company shall not be responsible for any losses or damages arising from the Customer’s use of the third party websites/app, or for any content or information made available to the Customer through such third party website/app. In the event the Customer chooses to buy the insurance policy offered by such third party service provider, it shall be the sole responsibility of the Customer to evaluate the merits and the risks associated with such decisions. The Customers have the option of exiting the website/app of such third party service providers and opt for any insurance policy of their choice.
            </li>

          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">J.	FINANCE</h5>
          <ul>
            <li>
              The contents of this section J are solely for the informational purposes only and nothing contained on the Platform shall be interpreted as legal, tax, investment, financial or any other advice. The Customers acknowledge that nothing contained on the Platform constitutes a solicitation, recommendation, endorsement or offer by the Company or the third party service provider (through our Platform) to buy any loan.
            </li>

            <li>
              In order to purchase the Selected Vehicle, Customers may avail loan facilities from any lenders of their choice and at their own will. In the event the Customer chooses to buy a loan from any financer (including those who have partnered with the Company for granting loans for Matter Vehicles), it shall be the sole responsibility of the Customer to evaluate the merits and the risks associated with such decisions.  The financing arrangement/loan shall be a separate contract between the Customer and the lender and the Company shall not be responsible in respect of the same in any manner. The Platform may occasionally contain links to third party service providers who offer loans for the Matter Vehicles. The Customer acknowledges that the Company shall not be responsible for any losses or damages arising from the Customers’ use of services provided by such lenders, or for any content or information made available to the Customer through such third party website/app.
            </li>

          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">K.	GENERAL TERMS AND CONDITIONS</h5>
          <ul>
            <li>
              Pre-Booking is optional and customers may choose not to Pre-Book the Selected Vehicle and the Pre-Booking is completely voluntary and entirely at the Customers discretion. By making a Pre-Booking with us, the Customers express their intention to purchase the Selected Vehicle specified by them and agree to be bound by these Online Pre-Booking T&C. This Online Pre-Booking T&C does not constitute an agreement of sale of the Matter Vehicles and does not create an obligation on the Company to manufacture the Matter Vehicles. When the Selected Vehicle Pre-Booked by the Customer is ready for delivery, the Company shall notify the Customer regarding the same, and if the Customer wishes to purchase the Selected Vehicle, a separate legally binding contract shall be agreed between the Customer, the Company and/or the  Authorised Entity. The purchase of the Selected Vehicle by the Customer, and the delivery by us shall be subject to the Customer’s Compliance with the Online Pre-Booking T&C and other applicable laws, regulations and guidelines.
            </li>
            <li>
              The Customers acknowledge that through the Platform, the Company has merely provided a means of facilitating a delivery of its Matter Vehicles to interested customers by allowing the customers an opportunity to “Pre-Book” their Selected Vehicle at their convenience. As the facility is being provided to the customers at  during the developmental stage of the Matter Vehicles (viz. prior to the commercial production of the Matter Vehicles), the Customers must note that the physical appearance of the Matter Vehicle available for sale may differ from what is displayed on the Platform or in advertisements and factors like the actual price, product specifications, features, performance and other claims made by the Company in respect of the Selected Vehicle are only indicative and may undergo a change if the business so demands or if required due to regulatory reasons. In case the changes/ variations made are not acceptable to the Customer, the Customer shall be entitled to seek a refund of the Pre-Booking Amount, without any interest, and the same shall be his/her only recourse against the Company and that the Company, its directors, consultants, business partners, agents, employees and representatives or its Authorised Entities shall not be liable for any other claims whatsoever.
            </li>
            <li>
              The Pre-Booking will not be confirmed unless the Pre-Booking Amount has been paid by the Customer through the Payment Gateway and received by the Company. The booking shall be confirmed and binding only after the receipt of the entire balance Purchase Price of the Selected Vehicle and submission of requisite supporting documents with the concerned Authorised Entity. Until then, the Pre-Booking is merely a request on the Customer’s part and an indication of an intention to sell on the part of the Company and does not result in booking confirmation or contract of sale and does not impose financial implications on the Company or the Authorised Entity except as provided herein in these Online Pre-Booking T&C.
            </li>
            <li>
              Delivery of the Selected Vehicle is subject to “force majeure” conditions. The term “force majeure” shall mean any circumstances which are unusual, unforeseeable and are beyond the control of the Company and/or the concerned Authorised Entity, the consequence of which could not have been avoided even if all due care had been exercised, including but not limited to acts of God,  natural or other disaster, pandemic, weather conditions, fire, flood, typhoon, tempest, drought, war or threat of war, riot, civil strike, hostilities, political unrest, government action, non-receipt of any requisite approvals from governmental/quasi-governmental authority/ or any other competent authority, industrial dispute, nuclear incident, terrorist activity, sabotage, blockage, embargo,  transport strike, short supply of labour, fuel, raw material, or otherwise preventing or hindering the manufacture or delivery of the Matter Vehicles and all similar events beyond the control of the Company and/or the concerned Authorised Entity.
            </li>
            <li>
              The Company reserves the right to withdraw the Pre-Booking facility provided on the Platform at any time and the Company does not guarantee the continuance of the Pre-Booking facility made available to the customers and the Company, its director, employees, the Authorised Entities and consultants assume no liability for any loss arising out of the withdrawal.
            </li>
            <li>
              The Company reserves the right to change the Pre-Booking Amount, Purchase Price, models, variants, specifications, features, standard fitment and/or accessories of/for its Matter Vehicles as shown on the Platform, introduce new variants or their versions or discontinue earlier variants or versions, without any prior notice. The Company also reserves the right to change the specifications and features of the Matter Vehicles including the aspects of their performance such as its power, acceleration, range, etc. and in such case the Company shall publish notifications  of the changes made (either on the Platform or otherwise). In case the changes/ variations made are not acceptable to the Customer, he/she shall be entitled to seek a refund (without interest) of the Pre-Booking Amount and the same shall be the Customer’s only recourse against the Company.
            </li>
            <li>
              The Company reserves the right to change or alter any terms and conditions of this Online Pre-Booking T&C  without any prior notice.
            </li>
            <li>
              The Company reserves the right to reject without assigning any reason, an Online Pre-Booking made by a Customer having prior history of questionable charges including without limitation breach of any agreements by the Customer with the Company/Authorised Entity or breach/violation of any law or any charges imposed by issuing bank or breach of any policy.
            </li>
            <li>
              The Customers will be responsible for maintaining the confidentiality of the username, password and other details relating to their Account on the Platform and for all the activities that occur on the Account. If the Customers provide any information that is untrue, inaccurate, not correct or incomplete, the Company shall have the right to indefinitely suspend or terminate or block their access to the Platform.
            </li>
            <li>
              The Company would not be responsible for delay, loss or non-receipt of communication by the Customer due to incorrect contact details given at the time of registration of his/her Account on the Platform.
            </li>
            <li>
              The Company collects, stores, processes and uses the Customers’ information in accordance with the Company's Privacy Policy. By using the Platform and/ or by providing their information, the Customers consent to the collection and use of the information they disclose on the Platform by the Company in accordance with the Company's Privacy Policy. The Privacy Policy shall be deemed to form part of this Online Pre-Booking T&C by way of this reference. The Customers may view the Privacy Policy at  {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white' href="/privacy-policy">https://matter.in/privacy-policy</a>.
            </li>
            <li>
              The communications shared by the Company (in the form of emails, SMS, etc.) with the Customers shall be only for their personal use and the Customer acknowledges and agrees that he/she shall not be entitled to copy, post or broadcast it to any media including on the social media platforms.
            </li>
            <li>
            Offers and discounts (including the “Super early bird offer”,  “Mission No Emission offer” and “promotional code offers”)  and other services provided on the Platform by the Company or the concerned Authorised Entity are subject to the Customer’s eligibility and availability of the offer/discount/service. Neither the Company or the Authorised Entity guarantees the provision of the offer/discount/service displayed on the Platform and the Company reserves the right to withdraw any offer made, or discounts/services displayed at its sole discretion. Customers must check the detailed terms and conditions of the promotions/rewards/benefits before making a Pre-Booking.
            </li>
            <li>
              In the event of demise of the Customer who has Pre-Booked the Selected Vehicle, at the option of the nominee and at the request of the nominee, the Pre-Booking shall either stand transferred to the legal heir of the Customer or shall stand cancelled, and after the verification of the requisite documents, the Pre-Booking Amount shall be refunded to the nominee (without any interest) in accordance with section H of this Online Pre-Booking T&C.
            </li>
            <li>
              The Company, its directors, employees, the Authorised Entities, consultants, business partners, agents, and representatives assume no liability whatsoever under any circumstances whatsoever for any direct or indirect loss or damage arising from the Pre-Booking of the Selected Vehicle made by the Customers on the Platform.
            </li>
            <li>
              The Customer shall be liable to indemnify the Company against all claims, losses, damages or costs that may be incurred or suffered by the Company, its directors, business partners, employees, consultants, agents and representatives or its Authorised Entities arising out of any breach of these Online Pre-Booking T&C.
            </li>

          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">L.	GOVERNING LAW</h5>
          <p>
            This Online Pre-Booking T&C and all the rules and policies contained herein and the Customer’s usage of the Platform of the Company shall be governed and constructed in accordance with the laws of India.
          </p>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">M.	ARBITRATIONS</h5>
          <p>
            If any dispute arises between the Customers and the Company in relation to the Pre-Booking of Matter Vehicles on the Platform, in connection with the validity, interpretation, implementation or alleged breach of any provision of this Online Pre-Booking T&C and all the rules and policies contained herein, the dispute shall be referred to a sole arbitrator who shall be an independent and neutral third party identified by the Company. The Arbitration and Conciliation Act, 1996, shall govern the arbitration proceeding. The arbitration proceedings shall be in the English language. The seat and venue of arbitration shall be Ahmedabad.
          </p>

        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">N.	CONTACT US</h5>
          <p>
            For any complaints/grievances in relation to the Pre-Booking of Selected Vehicle, the Customer may reach out to Mr. Rajeshwar Prasad at
            customer.grievance@matter.in,
            the designated ‘Grievance Officer’ under the Consumer Protection (E-Commerce) Rules, 2020. mailto:The subject of the e-mail should state “Concerns arising from Pre-Booking of Selected Vehicle”. The Company shall immediately look into the same and undertake the requisite actions.
          </p>
        </div>
      </div>
      {/* <Footer /> */}

    </div>
  )
}

export default OnlinePreBookingTerms
