import { AnyAction } from 'redux'
import { ActionTypes } from '../constants/index'
import { Home } from '../constants/model'

let initialState: Home = new Home()
export default function homeReducer(
  state: any = initialState,
  action: AnyAction
) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST:
      return { ...state, ...payload }
    case ActionTypes.CANCEL_REASONS:
      return { ...state, ...payload }
    case ActionTypes.TOP_BANNER:
      return { ...state, ...payload }
    case ActionTypes.CONTACT_US:
      return { ...state, ...payload }
    case ActionTypes.PRODUCT_SPEC:
        return { ...state, ...payload }  
    case ActionTypes.FAQ_CATEGORIES:
      return { ...state, ...payload }
    case ActionTypes.FAQ_DETAILS:
      return { ...state, ...payload }
    case ActionTypes.TRENDING_FAQ:
    case ActionTypes.FAQ_APPLIED_SEARCH:
      return { ...state, ...payload }
    case ActionTypes.TRENDING_FAQ:
      return { ...state, ...payload }
    case ActionTypes.RESET_FAQ:
      return { ...state, ...payload }
    case ActionTypes.INNOVATION_HUB:
      return { ...state, ...payload }
    case ActionTypes.WHO_WE_ARE_REWARDS:
      return { ...state, ...payload }
    case ActionTypes.WHO_WE_ARE_EVENTS:
      return { ...state, ...payload }
    case ActionTypes.WHO_WE_ARE_DETAILS:
      return { ...state, ...payload }
    case ActionTypes.WHO_WE_ARE_LEADER_LIST:
      return { ...state, ...payload }
    case ActionTypes.UPDATE_WEB_APP_CONFIG:
      return { ...state, ...payload }
    case ActionTypes.BLOGS:
      return { ...state, ...payload }
    case ActionTypes.BLOG_DETAILS:
      return { ...state, ...payload }
    case ActionTypes.TERM_CONDITION_JOIN_WAITLIST:
      return { ...state, ...payload }
    case ActionTypes.TERM_CONDITION_ONLINE_PREBOOK:
      return { ...state, ...payload }
    case ActionTypes.HOME_PAGE_DETAILS:
      return { ...state, ...payload }
    case ActionTypes.TERMS_CONDITIONS_LEGAL_POLICY:
      return { ...state, ...payload }
    case ActionTypes.PRIVACY_POLICY:
      return { ...state, ...payload }
    case ActionTypes.TERM_CONDITION_ONLINE_PREBOOK:
      return { ...state, ...payload }

    case ActionTypes.NEWS:
      return { ...state, ...payload }
    case ActionTypes.AWARDS:
      return { ...state, ...payload }
    case ActionTypes.PRESS_RELEASES:
      return { ...state, ...payload }
    case ActionTypes.LEADERSHIP:
      return { ...state, ...payload }
    case ActionTypes.LOGOSANDTRADEMARK:
      return { ...state, ...payload }
    case ActionTypes.AERA5000e:
      return {...state,...payload}
    case ActionTypes.AERA5000:
      return { ...state, ...payload }
    case ActionTypes.AERA5000PLUS:
      return { ...state, ...payload }
    case ActionTypes.PRODUCTPORTFOLIO:
      return { ...state, ...payload }
    case ActionTypes.PRODUCT_PAGE_DETAILS:
      return { ...state, ...payload }
    case ActionTypes.EMI_CALCULATOR:
      return { ...state, ...payload }
    default:
      return state
  }
}
