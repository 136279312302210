import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Grid from '@mui/material/Grid'
import Icons from '../../../utils/icons'
import '../matterStream.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerModel } from '../../../reducers/reducerModel'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ClickAwayListener, useMediaQuery } from '@mui/material'
import moment from 'moment'
import { News, NewsCategory} from '../../../constants/model'
import ProgressiveImage from '../../../components/ProgressiveImage'
import React from 'react'

import { ActionTypes, AppData } from '../../../constants'
import Images from '../../../utils/images'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import homeActions from '../../../actions/homeActions'
import { TextField } from '@mui/material';
import { getUserToken } from '../../../utils/common'

const DisplayNews = () => {
  const dispatch: Function = useDispatch()
  const { 
    newsCategories, 
    newsType,
    awards, 
    pressRelease,
    leadership,
    logosAndTrademark,
    aera5000,
    aera5000Plus,
    productPortfolio
  } = useSelector((store: ReducerModel) => store.home)
  
  const history = useHistory()

  //const newsCategoryList = ['Featured', 'Awards & Honors', 'Press Kit']
  const newsCategoryList = ['Featured', 'Press Kit']
  const [featured, setFeaturedNews] = useState(newsType || 'Featured')
  const [search, setSearch] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [totalSearch, setTotalSearch] = useState(0)
  const [featureSearch, setFeatureSearch] = useState(0)
  const [awardSearch, setAwardSearch] = useState(0)
  const [pressSearch, setPressSearch] = useState(0)
  const [displaySearch, setDisplaySearch] = useState(false)
  const [filterNews, setFilterNews] = useState<any[]>(newsCategories)  
  const [searchList, setSearchList] = useState<News[]>([])
  const [sliceMore, setSliceMore] = useState(3)
      
  const isSmall = useMediaQuery('(min-width:768px)');
  const isMobile = useMediaQuery('(max-width:576px)');

  useEffect(() => {            
    window.scrollTo(0, 0)   
    
  },[])  

  // const handleSearch = (seachedNews: []) => {
  //   const combine = filterNews.filter((newsCategory: NewsCategory, index) => {
  //     return newsCategory.allnews.filter((item) => item.newsTitle.toLocaleLowerCase().includes(searchFAQ.toLocaleLowerCase()))                     
  //   })        

  //   const result = filterNews.reduce((newsc: NewsCategory)=> newsc.allnews.filter((item) => item.newsTitle.includes(searchFAQ)))
  //   console.log(combine)
  // }
  //Category Blogs 
  const handleCategoryBlogs = (categoryName: string) => {
    setFeaturedNews(categoryName)    
    
    const blogs : any[] = []
    
    newsCategories.filter((blog:any) => (blog.categoryName == categoryName)).map((blogCategory: NewsCategory) => {    
      blogCategory.allnews.sort((a, b) => {          
          return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
        }).map((blog)=> {
          blogs.push(blog)
        })        
    })     
  }
  
  const download = (url: string, name: string) => {    
    const userToken = getUserToken()    
    
    !userToken ? (history.push('/login'),
    dispatch({
      type: ActionTypes.NEWS,
      payload: {
        downloadNews: true,
      },
    })) : 
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          //setFetching(false);
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";

          if (name && name.length) a.download = name;
          document.body.appendChild(a);
          a.click();
        })
        .catch(() => console.log('error'));
  }

  const handleSearch = () => {    
    setDisplaySearch(true)
  }

  const handleClose = () => {
    //setDisplaySearch(false)
    if(search === '') {
      setDisplaySearch(false)
    }
    setSearchValue('')
    setSearch('')
    setTotalSearch(0)
    setFeatureSearch(0)
    setAwardSearch(0)
    setPressSearch(0)
  }

  const handleDownload = () => {

  }

  return (
    <div className="matter-stream">
      <Header />
      <h1 className="visuallyHidden">Category Blogs</h1>        

      <div className="matter-news-content content-container">  

        <div className="flex al-center js-between monument news-title">
          <div className="flex al-center matter-news">
            <p className="category-title partnerLabelColor pointer" onClick={() => {
              history.push('/matter-stream')
              }}>
                Matter Stream 
                <img style={{ marginLeft: '14px' }} src={Icons.RightChevronIconBlack} alt='Right Caret Black Color Icon' /> 
            </p>
            <p className="category-title-news">News</p>
          </div>    
            <ClickAwayListener
              onClickAway={() => {
                document
                  .querySelector('.faq-search-list')
                  ?.classList.remove('show')                
              }}
            >
            <div className="ask-cover">
              {!isMobile ? <><input
                value={search}                
                onChange={(event) => {
                  event.preventDefault()
                  const value = event.target.value                  
                  setSearch(event.target.value)
                  if (value.length >= 3) {      
                    setSearchValue(value)     
                    let feature = 0         
                    newsCategories.map((newsCategory: NewsCategory, index) =>                                                                 
                        {newsCategory.allnews
                          .filter((val) => val.newsTitle.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
                          .map((item, index)=> {
                            feature++
                        })
                      })
                    
                      console.log('sum : ' + feature)
                    setFeatureSearch(feature)

                    let award = 0;  
                    awards.filter((val) => val.awardTitle.toLocaleLowerCase().includes(value.toLocaleLowerCase())) 
                        .map((item) => {
                          award++
                          })
                    setAwardSearch(award)

                    let press = 0
                    pressRelease.filter((val) => val.pressTitle.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
                          .map((item) => {
                            press++
                          })
                    setPressSearch(press)

                    setTotalSearch(feature + press)    
                    
                    //handleCategoryBlogs(news)
                    if(!feature) {
                      console.log('print ho jaa')
                      setFeaturedNews('Press Kit')
                    }
                    
                    if(!press) {
                      console.log('print ho jaa')
                      setFeaturedNews('Featured')
                    }
                                        
                    // newsCategories.map((newsCategory: NewsCategory, index) =>                                                                 
                    //   {newsCategory.allnews
                    //     .map((item, index)=> {
                    //       setSearchList([...searchList, item])
                    //   })
                    // })
                    let searchlist: News[] = []
                      newsCategories.map((newsCategory: NewsCategory, index) =>                                                                 
                        {newsCategory.allnews.filter((val) => val.newsTitle.toLocaleLowerCase()
                          .includes(value.toLocaleLowerCase()))
                          .map((item, index)=> {
                            searchlist.push(item)
                            //console.log(item)
                        })
                      })

                    console.log(searchlist)
                    console.log(value)
                    setSearchList(searchlist)
                    
                  } else {
                    setSearchValue('')
                    setTotalSearch(0)
                    setFeatureSearch(0)
                    setAwardSearch(0)
                    setPressSearch(0)
                  }
                }}
                //placeholder="Ask away!"
                type="text"
              /> 
              {search ? <img
                onClick={() => {
                  handleClose()
                }}
                src={Icons.CloseIconGray}
              />:
              <img
                onClick={() => {
                  
                }}
                src={Icons.SearchGrayIcon}
              /> 
              }
              </>
              : 
              searchValue === '' ? <img
                className="pointer"
                onClick={() => {
                  handleSearch()
                }}
                src={Icons.SearchGrayIcon}
              /> : <img
              className="pointer"
              onClick={() => {
                handleSearch()
              }}
              src={Icons.SearchRedIcon}
            />}                            
            </div>
          </ClickAwayListener>
      </div>      

      {totalSearch > 0 && <p className="totalSearch">{totalSearch < 10 ? "0" + totalSearch : totalSearch} results found for: <span className="searchValue">{searchValue}</span></p>}
      {(!totalSearch && searchValue) && <p className="totalSearch">No results found for: <span className="searchValue">{searchValue}</span></p>}

      {/* category and news */}
      {(!totalSearch && searchValue) ? <></> : <div className="flex blog-section">                
        {isSmall ? <>{newsCategoryList.map((news, index) =>           
          // <p className={"category-sub-title small subText pointer " + (news === featured && "boldName")} 
          //   onClick={() => {                    
          //     handleCategoryBlogs(news)
          //     dispatch({
          //       type: ActionTypes.NEWS,
          //       payload: {
          //         newsType: news,
          //       },
          //     })
          // }}>
          <>
            {(searchValue === '') && <p className={"category-sub-title small subText pointer " + (news === featured && "boldName")} 
            onClick={() => {                    
              handleCategoryBlogs(news)
              dispatch({
                type: ActionTypes.NEWS,
                payload: {
                  newsType: news,
                },
              })
          }}>{news}</p>}
            {(featureSearch > 0 && news === 'Featured') && <p className={"category-sub-title small subText pointer " + (news === featured && "boldName")} 
            onClick={() => {                    
              handleCategoryBlogs(news)
              dispatch({
                type: ActionTypes.NEWS,
                payload: {
                  newsType: news,
                },
              })
          }}>{news}&#40;{featureSearch < 10 ? "0" + featureSearch : featureSearch}&#41;</p>}
            {/* {(awardSearch > 0 && news === 'Awards & Honors') && <>{news}&#40;{awardSearch < 10 ? "0" + awardSearch : awardSearch}&#41;</>} */}
            {(pressSearch > 0 && news === 'Press Kit') && <p className={"category-sub-title small subText pointer " + (news === featured && "boldName")} 
            onClick={() => {                    
              handleCategoryBlogs(news)
              dispatch({
                type: ActionTypes.NEWS,
                payload: {
                  newsType: news,
                },
              })
          }}>{news}&#40;{pressSearch < 10 ? "0" + pressSearch : pressSearch}&#41;</p>}                
          {/* </p> */}
          </>
        )}</>
        : 
        <Swiper
          spaceBetween={0}
          //width={300}
          slidesPerView={3}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {newsCategoryList.map((news, index) =>           
            <SwiperSlide>
              {/* <p className={"news-category small subText pointer " + (news === featured && "boldName")} onClick={() => {                    
                  handleCategoryBlogs(news)
                  dispatch({
                    type: ActionTypes.NEWS,
                    payload: {
                      newsType: news,
                    },
                  })
              }}> */}
              <>
                {(searchValue === '') && <p className={"news-category small subText pointer " + (news === featured && "boldName")} onClick={() => {                    
                  handleCategoryBlogs(news)
                  dispatch({
                    type: ActionTypes.NEWS,
                    payload: {
                      newsType: news,
                    },
                  })
              }}>{news}</p>}
                {(featureSearch > 0 && news === 'Featured') && <p className={"news-category small subText pointer " + (news === featured && "boldName")} onClick={() => {                    
                  handleCategoryBlogs(news)
                  setSliceMore(3)
                  dispatch({
                    type: ActionTypes.NEWS,
                    payload: {
                      newsType: news,
                    },
                  })
              }}>{news}&#40;{featureSearch < 10 ? "0" + featureSearch : featureSearch}&#41;</p>}
                {/* {(awardSearch > 0 && news === 'Awards & Honors') && <>{news}&#40;{awardSearch < 10 ? "0" + awardSearch : awardSearch}&#41;</>} */}
                {(pressSearch > 0 && news === 'Press Kit') && <p className={"news-category small subText pointer " + (news === featured && "boldName")} onClick={() => {                    
                  handleCategoryBlogs(news)
                  setSliceMore(3)
                  dispatch({
                    type: ActionTypes.NEWS,
                    payload: {
                      newsType: news,
                    },
                  })
              }}>{news}&#40;{pressSearch < 10 ? "0" + pressSearch : pressSearch}&#41;</p>}                
              {/* </p>     */}
              </>
            </SwiperSlide>                                                                                  
          )}
        </Swiper>}
      </div> }

      {/* category and featured news */}
      {featured === 'Featured' && <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
        {/* search result */}
        {searchValue ? 
          <div className="blogs-section" style={{ marginBottom: '48px' }}>             
          {isSmall ?
          <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>                      
            {
            // newsCategories.map((newsCategory: NewsCategory, index) =>                                                                    
            //     (newsCategory.allnews.filter((val) => val.newsTitle.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
              searchList
                .sort((a, b) => {          
                  return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
                })
                .map((item, index)=>                 
                  <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', marginBottom: '48px' }}>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      window.open(item.newsURL)                                 
                    }}>
                    <div className="blog-posts monument pointer" onClick={() => {                    
                    
                    }
                      }>
                      <ProgressiveImage alt="Matter Blogs"                    
                        className="more-posts-blog-img" 
                        imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                      <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                      <span className="dot"></span>                     
                      <p className="date ml-8">{item.newsPublisher}</p> 
                      {/* <span className="dot ml-8"></span> */}
                      {/* <p className="date ml-8">{item.readTime} min read</p>  */}
                      <p className="blog-data-title mt-8">                        
                        {item.newsTitle} 
                      </p>
                    </div>
                    </Link>
                  </Grid>
                // ))
              )}                        
              </Grid>
              :
              <>
                <Grid container spacing={{xs: 1.5, sm: 3}} rowSpacing={{ md: 10 }} style={{ marginTop: '0' }}> 
                  {/* {newsCategories.map((newsCategory: NewsCategory, index) =>                                                                    
                    (newsCategory.allnews.filter((val) => val.newsTitle.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())).slice(0, sliceMore).map((item, index)=> (                 */}
                    {searchList.sort((a, b) => {          
                      return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
                    }).slice(0, sliceMore).map((item, index)=> (
                      <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', paddingBottom: '48px' }}>
                        <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                          window.open(item.newsURL)                                 
                        }}>
                          <div className="blog-posts monument pointer" onClick={() => { }
                            }>
                            <ProgressiveImage alt="Matter Blogs"                    
                              className="more-posts-blog-img" 
                              imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                              previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                            <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                            <span className="dot"></span>                     
                            <p className="date ml-8">{item.newsPublisher}</p> 
                            {/* <span className="dot ml-8"></span> */}
                            {/* <p className="date ml-8">{item.readTime} min read</p> */}
                            <p className="blog-data-title mt-8">
                              {item.newsTitle}
                            </p>
                          </div>
                        </Link>
                      </Grid>
                    ))}
                      {/* ))
                    ))}  */}

                  {((searchList.length > sliceMore) && isMobile) &&
                      <div style={{ paddingLeft: "12px" }}>                        
                          <p className="more-posts primary pointer" onClick={() => {
                            setSliceMore(sliceMore + 3)
                          }}>
                            <span className="underline pb-2">Load More</span>
                            <img
                              className="ml-4"
                              src={Icons.RightChevronIconBlue}
                              alt="Right Chevron Icon Blue"
                            />
                          </p>
                      </div>
                    }

                    {/* {sliceMore < (isBlogsLoaded ? categoryNews : filteredBlogs).length && 
                      <div>
                        {((isBlogsLoaded ? categoryNews : filteredBlogs).length > 3 && isMobile) && 
                          <p className="more-posts primary pointer" onClick={() => {
                            setSliceMore(sliceMore + 3)
                          }}>
                            <span className="underline pb-2">Load More</span>
                            <img
                              className="ml-4"
                              src={Icons.RightChevronIconBlue}
                              alt="Right Chevron Icon Blue"
                            />
                          </p>
                        }
                      </div>}                  */}
                </Grid>
              </>}                   
          
        </div> : <>
        {newsCategories.map((newsCategory: NewsCategory, index) =>            
          <div key={index} className="blogs-section" style={{ marginBottom: '48px' }}>
            <div className="category-title" style={{ marginBottom: '16px' }}>
              <h5 className="category-sub-title black monument" style={{ padding: '0', fontSize: '20px' }}>
                {newsCategory.categoryName}                
              </h5>
              {(newsCategory.allnews.length > 3 && isSmall) && 
                <p className="more-posts primary pointer" onClick={() => {
                    history.push('/matter-stream/news/category')       
                    dispatch({
                      type: ActionTypes.NEWS,
                      payload: {
                        newsCategory: newsCategory.categoryName,
                      },
                    })
                }}>
                  <span className="underline pb-2">More</span>
                  <img
                    className="ml-4"
                    src={Icons.RightChevronIconBlue}
                    alt="Right Chevron Icon Blue"
                  />
                </p>
              }
            </div>            
            
            {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
              {newsCategory.allnews.slice(0,3).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0' }}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(item.newsURL)                                 
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {                    
                   
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.newsPublisher}</p> 
                    {/* <span className="dot ml-8"></span> */}
                    {/* <p className="date ml-8">{item.readTime} min read</p> */}
                    <p className="blog-data-title mt-8">
                      {item.newsTitle}
                    </p>
                  </div>
                  </Link>
                </Grid>
              )}               
            </Grid>
            :<>
            <Swiper
              spaceBetween={24}
              width={300}
              slidesPerView={1}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {newsCategory.allnews.slice(0,3).map((item, index)=>                 
                <SwiperSlide>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(item.newsURL)                                 
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {                    
                    
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.newsPublisher}</p> 
                    {/* <span className="dot ml-8"></span> */}
                    {/* <p className="date ml-8">{item.readTime} min read</p> */}
                    <p className="blog-data-title mt-8">
                      {item.newsTitle}
                    </p>
                  </div>
                  </Link>
                  </SwiperSlide>
              )}
            </Swiper>
            {newsCategory.allnews.length > 3 && 
              <p className="more-posts primary pointer" onClick={() => {
                  history.push('/matter-stream/news/category')  
                  dispatch({
                    type: ActionTypes.NEWS,
                    payload: {
                      newsCategory: newsCategory.categoryName,
                    },
                  })                  
              }}>
                <span className="underline pb-2">More</span>
                <img
                  className="ml-4"
                  src={Icons.RightChevronIconBlue}
                  alt="Right Chevron Icon Blue"
                />
              </p>
            }</>
            }
          </div>
        )}
      </>}
      </div>}  

      {/* category and featured news */}
      {/* {featured === 'Awards & Honors' && <div className="matter-stream-content-blogs" style={{ marginTop: '0' }}>                
        {searchValue ? <div className="blogs-section ">                                    
            <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>            
              {awards.filter((val) => val.awardTitle.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index} style={{ marginBottom: '48px', paddingTop: '0' }}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(item.awardURL)
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {}}>
                    {isSmall ? <><div style={{ padding: '50px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '24px', height: '290px' }}>
                      <ProgressiveImage alt="Matter Blogs"                    
                        //className=" mb-0" 
                        imgSrc={process.env.STRAPI_URL + item.awardImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.awardThumbNailImageWeb} />                    
                    </div>
                    <p className="date mr-8">{moment(item.awardYear).format('YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.awardPublisher}</p> 
                    <p className="blog-data-title mt-8">
                      {item.awardTitle}
                    </p></>
                    :
                    <div className="awards">
                      <ProgressiveImage alt="Matter Blogs"                    
                        //className=" mb-0" 
                        imgSrc={process.env.STRAPI_URL + item.awardImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.awardThumbNailImageWeb} />                    
                    
                      <div className="ml-8 flex js-center" style={{ flexDirection: 'column' }}>
                        <div>
                          <p className="date mr-8">{moment(item.awardYear).format('YYYY')}</p>
                          <span className="dot"></span>                     
                          <p className="date ml-8">{item.awardPublisher}</p> 
                        </div>
                        <div>
                          <p className="blog-data-title">
                            {item.awardTitle}
                          </p>
                        </div>
                      </div>                      
                    </div>}
                  </div>
                  </Link>
                </Grid>
              )}               
            </Grid>
          </div>
          :
          <div className="blogs-section ">                                    
            <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
              {awards.map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index} style={{ marginBottom: '48px', paddingTop: '0' }}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(item.awardURL)
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {
                   
                  }
                    }>
                    {isSmall ? <><div style={{ padding: '50px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '24px', height: '290px' }}>
                      <ProgressiveImage alt="Matter Blogs"                    
                        //className=" mb-0" 
                        imgSrc={process.env.STRAPI_URL + item.awardImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.awardThumbNailImageWeb} />                    
                    </div>
                    <p className="date mr-8">{moment(item.awardYear).format('YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.awardPublisher}</p> 
                    <p className="blog-data-title mt-8">
                      {item.awardTitle}
                    </p></>
                    :
                    <div className="awards">
                      <ProgressiveImage alt="Matter Blogs"                    
                        //className=" mb-0" 
                        imgSrc={process.env.STRAPI_URL + item.awardImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.awardThumbNailImageWeb} />                    
                    
                      <div className="ml-8 flex js-center" style={{ flexDirection: 'column' }}>
                        <div>
                          <p className="date mr-8">{moment(item.awardYear).format('YYYY')}</p>
                          <span className="dot"></span>                     
                          <p className="date ml-8">{item.awardPublisher}</p> 
                        </div>
                        <div>
                          <p className="blog-data-title">
                            {item.awardTitle}
                          </p>
                        </div>
                      </div>                      
                    </div>}
                  </div>
                  </Link>
                </Grid>
              )}               
            </Grid>
          </div>
        }        
      </div>}   */}

      {/* category and featured news */}
      {featured === 'Press Kit' && 
      <div>
        {/* Press Release */}
        {searchValue ? <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>                 
          <div className="blogs-section" style={{ marginBottom: '48px' }}>                   
            
            {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
              {pressRelease.filter((val) => val.pressTitle.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
              .sort((a,b) => {          
                return new Date(b.pressDate).getTime() - new Date(a.pressDate).getTime()
              }).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0' }}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(process.env.STRAPI_URL + item.pressFile)                                 
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {                    
                  
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.pressImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.pressThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.pressDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.pressPublisher}</p> 
                    <p className="blog-data-title mt-8">
                      {item.pressTitle}
                    </p>                    
                  </div>
                  </Link>   
                  <p className="download pointer" 
                    onClick={()=> download(process.env.STRAPI_URL + item.pressFile, item.pressTitle)}>
                    Download
                  </p>                                                   
                </Grid>
              )}               
            </Grid>
            :
            // <Swiper
            //   spaceBetween={24}
            //   width={300}
            //   slidesPerView={1}
            //   onSlideChange={() => console.log('slide change')}
            //   onSwiper={(swiper) => console.log(swiper)}
            // >
            //   {pressRelease.slice(0,3).sort((a,b) => {          
            //       return new Date(b.pressDate).getTime() - new Date(a.pressDate).getTime()
            //     }).map((item, index)=>                 
            //     <SwiperSlide>
            //       <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
            //         window.open(process.env.STRAPI_URL + item.pressFile)                                 
            //       }}>
            //       <div className="blog-posts monument pointer" onClick={() => {                    
                  
            //       }
            //         }>
            //         <ProgressiveImage alt="Matter Blogs"                    
            //           className="more-posts-blog-img" 
            //           imgSrc={process.env.STRAPI_URL + item.pressImageWeb} 
            //           previewSrc={process.env.STRAPI_URL + item.pressThumbNailImageWeb} />                    
            //         <p className="date mr-8">{moment(item.pressDate).format('MMM DD, YYYY')}</p>
            //         <span className="dot"></span>                     
            //         <p className="date ml-8">{item.pressPublisher}</p> 
            //         <p className="blog-data-title mt-8">
            //           {item.pressTitle}
            //         </p>                    
            //       </div>
            //       </Link>   
            //       <p className="download pointer" 
            //         onClick={()=> download(process.env.STRAPI_URL + item.pressFile, item.pressTitle)}>
            //         Download
            //       </p>                                                   
            //     </SwiperSlide>
            //   )}
            // </Swiper>
            <>
                <Grid container spacing={{xs: 1.5, sm: 3}} rowSpacing={{ md: 10 }} style={{ marginTop: '0' }}> 
                  {/* {newsCategories.map((newsCategory: NewsCategory, index) =>                                                                    
                    (newsCategory.allnews.filter((val) => val.newsTitle.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())).slice(0, sliceMore).map((item, index)=> (                 */}
                    {pressRelease.filter((val) => val.pressTitle.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                      .sort((a,b) => {          
                        return new Date(b.pressDate).getTime() - new Date(a.pressDate).getTime()
                      }).slice(0, sliceMore).map((item, index)=> (
                      <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', paddingBottom: '48px' }}>
                        <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                          window.open(process.env.STRAPI_URL + item.pressFile)
                        }}>
                          <div className="blog-posts monument pointer" onClick={() => { }
                            }>
                            <ProgressiveImage alt="Matter Blogs"                    
                              className="more-posts-blog-img" 
                              imgSrc={process.env.STRAPI_URL + item.pressImageWeb} 
                              previewSrc={process.env.STRAPI_URL + item.pressThumbNailImageWeb} />                    
                            <p className="date mr-8">{moment(item.pressDate).format('MMM DD, YYYY')}</p>
                            <span className="dot"></span>                     
                            <p className="date ml-8">{item.pressPublisher}</p> 
                            <p className="blog-data-title mt-8">
                              {item.pressTitle}
                            </p>                    
                          </div>
                        </Link>
                      </Grid>
                    ))}
                      {/* ))
                    ))}  */}

                  {((pressRelease.filter((val) => val.pressTitle.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())).length > sliceMore) && isMobile) &&
                      <div style={{ paddingLeft: "12px" }}>                        
                          <p className="more-posts primary pointer" onClick={() => {
                            setSliceMore(sliceMore + 3)
                          }}>
                            <span className="underline pb-2">Load More</span>
                            <img
                              className="ml-4"
                              src={Icons.RightChevronIconBlue}
                              alt="Right Chevron Icon Blue"
                            />
                          </p>
                      </div>
                    }
                </Grid>
              </>
            }
          </div>                
        </div>
        :
        <div>
          <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>                 
            <div className="blogs-section" style={{ marginBottom: '48px' }}>
              <div className="category-title" style={{ marginBottom: '16px' }}>
                <h5 className="category-sub-title black monument" style={{ padding: '0', fontSize: '20px' }}>
                  {/* {newsCategory.categoryName}                 */}
                  Press Release
                </h5>
                
                {(pressRelease.length > 3 && isSmall) &&
                  <p className="more-posts primary pointer" onClick={() => {
                      history.push('/matter-stream/news/press-kit?press=Press Release')                    
                  }}>
                    <span className="underline pb-2">More</span>             
                    <img
                      className="ml-4"
                      src={Icons.RightChevronIconBlue}
                      alt="Right Chevron Icon Blue"
                    />
                  </p>
                }
              </div>            
              
              {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
                {pressRelease.slice(0,3).sort((a,b) => {          
                  return new Date(b.pressDate).getTime() - new Date(a.pressDate).getTime()
                }).map((item, index)=>                 
                  <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0' }}>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      window.open(process.env.STRAPI_URL + item.pressFile)                                 
                    }}>
                    <div className="blog-posts monument pointer" onClick={() => {                    
                    
                    }
                      }>
                      <ProgressiveImage alt="Matter Blogs"                    
                        className="more-posts-blog-img" 
                        imgSrc={process.env.STRAPI_URL + item.pressImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.pressThumbNailImageWeb} />                    
                      <p className="date mr-8">{moment(item.pressDate).format('MMM DD, YYYY')}</p>
                      <span className="dot"></span>                     
                      <p className="date ml-8">{item.pressPublisher}</p> 
                      <p className="blog-data-title mt-8">
                        {item.pressTitle}
                      </p>                    
                    </div>
                    </Link>   
                    <p className="download pointer" 
                      onClick={()=> download(process.env.STRAPI_URL + item.pressFile, item.pressTitle)}>
                      Download
                    </p>                                                   
                  </Grid>
                )}               
              </Grid>
              :<>
              <Swiper
                spaceBetween={24}
                width={300}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {pressRelease.slice(0,3).sort((a,b) => {          
                  return new Date(b.pressDate).getTime() - new Date(a.pressDate).getTime()
                }).map((item, index)=>                 
                  <SwiperSlide>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      window.open(process.env.STRAPI_URL + item.pressFile)                                 
                    }}>
                    <div className="blog-posts monument pointer" onClick={() => {                    
                    
                    }
                      }>
                      <ProgressiveImage alt="Matter Blogs"                    
                        className="more-posts-blog-img" 
                        imgSrc={process.env.STRAPI_URL + item.pressImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.pressThumbNailImageWeb} />                    
                      <p className="date mr-8">{moment(item.pressDate).format('MMM DD, YYYY')}</p>
                      <span className="dot"></span>                     
                      <p className="date ml-8">{item.pressPublisher}</p> 
                      <p className="blog-data-title mt-8">
                        {item.pressTitle}
                      </p>                    
                    </div>
                    </Link>   
                    <p className="download pointer" 
                      onClick={()=> download(process.env.STRAPI_URL + item.pressFile, item.pressTitle)}>
                      Download
                    </p>                                                   
                  </SwiperSlide>
                )}
              </Swiper>
              {pressRelease.length > 3 &&
                <p className="more-posts primary pointer" onClick={() => {
                    history.push('/matter-stream/news/press-kit?press=Press Release')                    
                }}>
                  <span className="underline pb-2">More</span>             
                  <img
                    className="ml-4"
                    src={Icons.RightChevronIconBlue}
                    alt="Right Chevron Icon Blue"
                  />
                </p>
              }</>
              }
            </div>                
          </div>

        {/* Product PortFolio */}
        <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
          {/* {newsCategories.filter((item => item.categoryName === 'Interviews')).map((newsCategory: NewsCategory, index) =>             */}
            <div className="blogs-section" style={{ marginBottom: '48px' }}>
              <div className="category-title" style={{ marginBottom: '16px' }}>
                <h5 className="category-sub-title black monument" style={{ padding: '0', fontSize: '20px' }}>
                  {/* {newsCategory.categoryName}                 */}
                  Product Portfolio
                </h5>
                {/* {newsCategory.allnews.length > 0 &&  */}
                  {/* <p className="more-posts primary pointer" onClick={() => {
                      history.push('/matter-stream/news/press-kit?press=Product Portfolio')                    
                  }}>
                    <span className="underline pb-2">More</span>             
                    <img
                      className="ml-4"
                      src={Icons.RightChevronIconBlue}
                      alt="Right Chevron Icon Blue"
                    />
                  </p> */}
                {/* } */}
              </div>            
              
              {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
                {aera5000.filter((val)=> val.id == 1).map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0' }}>                  
                    <div className="blog-posts monument" onClick={() => {                    
                    
                      }
                      }>                    
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <img
                          className="ml-2"
                          src={Icons.Area5000Icon}
                          alt="Aera 5000 Icon"
                        />
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="" 
                          imgSrc={process.env.STRAPI_URL + item.aeraBikeImg}
                          previewSrc={process.env.STRAPI_URL + item.aeraBikeImg} /> 
                      </div>                                                            
                      <p className="small vietnam">
                        <span className="underline pointer" onClick={() => {
                            history.push('/matter-stream/news/press-kit?press=Product Portfolio&aera=Aera 5000')                    
                          }}>View All</span><span className="subText ml-6 mr-6">|</span>
                        <span className="underline pointer"
                          onClick={()=> download(process.env.STRAPI_URL + productPortfolio.filter((val) => val.productName === 'aera5000')[0].downloadAll, "Aera 5000")}>
                          Download All
                        </span><span className="subText ml-6 mr-6">|</span>
                        <span className="underline pointer"
                          onClick={()=> download(process.env.STRAPI_URL + productPortfolio.filter((val) => val.productName === 'aera5000')[0].specsheet, "Aera 5000 Specsheet")}
                        >Specsheet</span>                        
                      </p>                     
                    </div>                  
                  </Grid>
                ))}
                {aera5000Plus.filter((val)=> val.id == 5).map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0' }}>                  
                    <div className="blog-posts monument" onClick={() => {                    
                    
                      }
                      }>
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <img
                          className="ml-2"
                          src={Icons.Area5000PlusIcon}
                          alt="Aera 5000 Icon"
                        />
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="" 
                          imgSrc={process.env.STRAPI_URL + item.aeraBikeImg}
                          previewSrc={process.env.STRAPI_URL + item.aeraBikeImg} /> 
                      </div>                                                            
                      <p className="small vietnam">
                        <span className="underline pointer" onClick={() => {
                            history.push('/matter-stream/news/press-kit?press=Product Portfolio&aera=Aera 5000 Plus')                    
                          }}>View All</span><span className="subText ml-6 mr-6">|</span>
                        <span className="underline pointer"
                          onClick={()=> download(process.env.STRAPI_URL + productPortfolio.filter((val) => val.productName === 'aera5000Plus')[0].downloadAll, "Aera 5000 Plus")}>
                            Download All
                        </span><span className="subText ml-6 mr-6">|</span>
                        <span className="underline pointer"
                          onClick={()=> download(process.env.STRAPI_URL + productPortfolio.filter((val) => val.productName === 'aera5000Plus')[0].specsheet, "Aera 5000 Plus Specsheet")}
                        >Specsheet</span>
                      </p>              
                    </div>                  
                  </Grid>
                ))}              
              </Grid>
              :
              <Swiper
                spaceBetween={24}
                width={300}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {aera5000.filter((val)=> val.id == 1).map((item, index) => (
                  <SwiperSlide>
                    <div className="blog-posts monument" onClick={() => {                    
                    
                      }
                      }>                    
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <img
                          className="ml-2"
                          src={Icons.Area5000Icon}
                          alt="Aera 5000 Icon"
                        />
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="contain-img" 
                          imgSrc={process.env.STRAPI_URL + item.aeraBikeImg}
                          previewSrc={process.env.STRAPI_URL + item.aeraBikeImg} /> 
                      </div>                                                            
                      <p className="small vietnam">
                        <span className="underline pointer" onClick={() => {
                            history.push('/matter-stream/news/press-kit?press=Product Portfolio&aera=Aera 5000')                    
                          }}>View All</span><span className="subText ml-6 mr-6">|</span>
                        <span className="underline pointer"
                          onClick={()=> download(process.env.STRAPI_URL + productPortfolio.filter((val) => val.productName === 'aera5000')[0].downloadAll, "Aera 5000")}
                        >Download All</span><span className="subText ml-6 mr-6">|</span>
                        <span className="underline pointer"
                          onClick={()=> download(process.env.STRAPI_URL + productPortfolio.filter((val) => val.productName === 'aera5000')[0].specsheet, "Aera 5000 Specsheet")}
                        >Specsheet</span>
                      </p>                     
                    </div>   
                  </SwiperSlide>                                 
                ))}

                {aera5000Plus.filter((val)=> val.id == 5).map((item, index) => (
                  <SwiperSlide>
                    <div className="blog-posts monument" onClick={() => {                    
                    
                      }
                      }>
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <img
                          className="ml-2"
                          src={Icons.Area5000PlusIcon}
                          alt="Aera 5000 Icon"
                        />
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="contain-img" 
                          imgSrc={process.env.STRAPI_URL + item.aeraBikeImg}
                          previewSrc={process.env.STRAPI_URL + item.aeraBikeImg} /> 
                      </div>                                                            
                      <p className="small vietnam">
                        <span className="underline pointer" onClick={() => {
                            history.push('/matter-stream/news/press-kit?press=Product Portfolio&aera=Aera 5000 Plus')                    
                          }}>View All</span><span className="subText ml-6 mr-6">|</span>
                        <span className="underline pointer"
                          onClick={()=> download(process.env.STRAPI_URL + productPortfolio.filter((val) => val.productName === 'aera5000Plus')[0].downloadAll, "Aera 5000 Plus")}
                        >Download All</span><span className="subText ml-6 mr-6">|</span>
                        <span className="underline pointer"
                          onClick={()=> download(process.env.STRAPI_URL + productPortfolio.filter((val) => val.productName === 'aera5000Plus')[0].specsheet, "Aera 5000 Plus Specsheet")}
                        >Specsheet</span>
                      </p>              
                    </div>                  
                  </SwiperSlide>
                ))}

              </Swiper>}
            </div>
          {/* )}         */}
        </div>

        {/* Leadership */}
        <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
          {/* {newsCategories.filter((item => item.categoryName === 'Interviews')).map((newsCategory: NewsCategory, index) =>             */}
            <div className="blogs-section" style={{ marginBottom: '48px' }}>
              <div className="category-title" style={{ marginBottom: '16px' }}>
                <h5 className="category-sub-title black monument" style={{ padding: '0', fontSize: '20px' }}>
                  {/* {newsCategory.categoryName}                 */}
                  Leadership
                </h5>              
              </div>            
              
              {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
                {leadership.map(leaderItem => (
                  <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '0', marginBottom: '36px' }}>                  
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                        window.open(process.env.STRAPI_URL + leaderItem.founderBio)                                 
                      }}>
                      <div className="blog-posts monument pointer" onClick={() => {                    
                      
                        }
                        }>
                        <div style={{ padding: '20px 50px 0 50px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '24px', height: '290px' }}>
                          <ProgressiveImage alt="Matter Blogs"                    
                            className="" 
                            imgSrc={process.env.STRAPI_URL + leaderItem.founderImageWeb}
                            previewSrc={process.env.STRAPI_URL + leaderItem.founderThumbNailImageWeb} 
                            /> 
                        </div>  
                        <p className="ex-large vietnam">                         
                          <span className="ex-large semi-bold black mt-12">
                            {leaderItem.founderName}
                          </span>
                          &nbsp;
                          <span className="ex-large subText" style={{ fontWeight: '400' }}>
                          &#40;{leaderItem.founderDesignation}&#41;
                          </span>                                                                                      
                        </p>
                        
                      </div>
                    </Link>
                    <p className="download" style={{ textDecoration: 'none' }}>
                      Download&nbsp;
                      <span className="download pointer black" 
                        onClick={()=> download(process.env.STRAPI_URL + leaderItem.founderImageWeb, leaderItem.founderName)}>
                          Photo
                      </span>
                      <span>&nbsp;|&nbsp;</span>
                      <span className="download pointer black" 
                        onClick={()=> download(process.env.STRAPI_URL + leaderItem.founderBio, leaderItem.founderName)}>
                          Bio
                      </span>                    
                    </p>
                  </Grid>
                ))}
              </Grid>
              :
              <Swiper
                spaceBetween={24}
                width={300}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {leadership.map(leaderItem => (
                  <SwiperSlide>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      window.open(process.env.STRAPI_URL + leaderItem.founderBio)                                 
                    }}>
                    <div className="blog-posts monument pointer" onClick={() => {                    
                    
                      }
                      }>
                      <div style={{ padding: '40px 20px 0 20px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '24px', height: '290px' }}>
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="" 
                          imgSrc={process.env.STRAPI_URL + leaderItem.founderImageWeb}
                          previewSrc={process.env.STRAPI_URL + leaderItem.founderThumbNailImageWeb} 
                          /> 
                      </div>  
                      <p className="ex-large vietnam">                         
                        <span className="ex-large semi-bold black mt-12">
                          {leaderItem.founderName}
                        </span>
                        &nbsp;
                        <span className="ex-large subText" style={{ fontWeight: '400' }}>
                        &#40;{leaderItem.founderDesignation}&#41;
                        </span>                                                                                      
                      </p>
                      
                    </div>
                    </Link>
                    <p className="download" style={{ textDecoration: 'none' }}>
                      Download&nbsp;
                      <span className="download pointer black" 
                        onClick={()=> download(process.env.STRAPI_URL + leaderItem.founderImageWeb, leaderItem.founderName)}>
                          Photo
                      </span>
                      <span>&nbsp;|&nbsp;</span>
                      <span className="download pointer black" 
                        onClick={()=> download(process.env.STRAPI_URL + leaderItem.founderBio, leaderItem.founderName)}>
                          Bio
                      </span>                    
                    </p>
                  </SwiperSlide>
                ))}
              </Swiper>}
            </div>
          {/* )}         */}
        </div>

        {/* Logos & Trademark */}
        <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
          {/* {newsCategories.filter((item => item.categoryName === 'Interviews')).map((newsCategory: NewsCategory, index) =>             */}
            <div className="blogs-section" style={{ marginBottom: '48px' }}>
              <div className="category-title" style={{ marginBottom: '16px' }}>
                <h5 className="category-sub-title black monument" style={{ padding: '0', fontSize: '20px' }}>
                  {/* {newsCategory.categoryName}                 */}
                  Logos & Trademark
                </h5>
                {logosAndTrademark.length > 3 && 
                  <p className="more-posts primary pointer" onClick={() => {
                      history.push('/matter-stream/news/press-kit?press=Logos %26 Trademark')                    
                  }}>
                    <span className="underline pb-2">More</span>
                    <img
                      className="ml-4"
                      src={Icons.RightChevronIconBlue}
                      alt="Right Chevron Icon Blue"
                    />
                  </p>
                }
              </div>            
              
              {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
                {logosAndTrademark.slice(0,3).map((item, index) => (
                  <Grid item xs={12} sm={6} md={4}  style={{ paddingTop: '0' }}>                  
                    <div className="blog-posts monument pointer" onClick={() => {                    
                    
                      }
                      }>
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="contain-img" 
                          imgSrc={process.env.STRAPI_URL + item.logoAndTrademark}
                          previewSrc={process.env.STRAPI_URL + item.logoAndTrademark}  /> 
                      </div>                                                            
                      <p className="download pointer" 
                        onClick={()=> download(process.env.STRAPI_URL + item.logoAndTrademark, "Logo")}>
                        Download
                      </p>                     
                    </div>
                    
                  </Grid>
                ))}

                {/* <Grid item xs={12} sm={6} md={4}  style={{ paddingTop: '0' }}>                  
                    <div className="blog-posts monument pointer" onClick={() => {                    
                    
                      }
                      }>
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="" 
                          imgSrc={Icons.Mini_Logo_Black} 
                          previewSrc={Icons.Mini_Logo_Black} /> 
                      </div>                                                            
                      <p className="download pointer" 
                        onClick={()=> download(Icons.Mini_Logo_Black, "T&C JWT")}>
                        Download
                      </p>                    
                    </div>
                    
                  </Grid> */}
              </Grid>
              :
              <Swiper
                spaceBetween={24}
                width={300}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {logosAndTrademark.slice(0,3).map((item, index) => (
                  <SwiperSlide>
                    <div className="blog-posts monument pointer" onClick={() => {                    
                    
                      }
                      }>
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="contain-img" 
                          imgSrc={process.env.STRAPI_URL + item.logoAndTrademark}
                          previewSrc={process.env.STRAPI_URL + item.logoAndTrademark} /> 
                      </div>                                                            
                      <p className="download pointer" 
                        onClick={()=> download(process.env.STRAPI_URL + item.logoAndTrademark, "Logo")}>
                        Download
                      </p>                     
                    </div>
                    
                  </SwiperSlide>                 
                ))}

                  {/* <SwiperSlide>                                   
                    <div className="blog-posts monument pointer" onClick={() => {                    
                    
                      }
                      }>
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="" 
                          imgSrc={Icons.Mini_Logo_Black} 
                          previewSrc={Icons.Mini_Logo_Black} /> 
                      </div>                                                            
                      <p className="download pointer" 
                        onClick={()=> download(Icons.Mini_Logo_Black, "T&C JWT")}>
                        Download
                      </p>                    
                    </div>
                    
                  </SwiperSlide>                  */}
              </Swiper>}
            </div>
            {/* )}         */}
          </div>
        </div>}     
        
      </div>}    
      
      {isMobile && <Dialog
          //maxWidth={"md"}
          //aria-labelledby="customized-dialog-title"
          open={displaySearch}
          //className="verify-email confirmation-dialog"
          className="matter-dialog"
          fullWidth
          style={isMobile ? {top: '0', bottom: 'auto'} : {}}
        >          
          <DialogContent >      
            <ClickAwayListener
              onClickAway={() => {
                setDisplaySearch(false)
              }}
            >                            
              <div className="ask-cover">
                <input
                  value={search}  
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      setDisplaySearch(false)
                    }
                  }}              
                  onChange={(event) => {
                    event.preventDefault()
                    const value = event.target.value                  
                    setSearch(event.target.value)                    
                    if (value.length >= 3) {      
                      setSearchValue(value)     
                      let feature = 0         
                      newsCategories.map((newsCategory: NewsCategory, index) =>                                                                 
                          {newsCategory.allnews
                            .filter((val) => val.newsTitle.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
                            .map((item, index)=> {
                              feature++
                          })
                        })
                      
                        console.log('sum : ' + feature)
                      setFeatureSearch(feature)

                      let award = 0;  
                      awards.filter((val) => val.awardTitle.toLocaleLowerCase().includes(value.toLocaleLowerCase())) 
                          .map((item) => {
                            award++
                            })
                      setAwardSearch(award)

                      let press = 0
                      pressRelease.filter((val) => val.pressTitle.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
                            .map((item) => {
                              press++
                            })
                      setPressSearch(press)

                      setTotalSearch(feature + press)      
                      
                      if(!feature) {
                        console.log('print ho jaa')
                        setFeaturedNews('Press Kit')
                      }
                      
                      if(!press) {
                        console.log('print ho jaa')
                        setFeaturedNews('Featured')
                      }

                      // {newsCategories.map((newsCategory: NewsCategory, index) =>                                                                    
                      //   (((newsCategory.allnews.filter((val) => val.newsTitle.toLocaleLowerCase()
                      //   .includes(searchValue.toLocaleLowerCase())).length > sliceMore) 
                            
                      let searchlist: News[] = []
                      newsCategories.map((newsCategory: NewsCategory, index) =>                                                                 
                        {newsCategory.allnews.filter((val) => val.newsTitle.toLocaleLowerCase()
                          .includes(value.toLocaleLowerCase()))
                          .map((item, index)=> {
                            searchlist.push(item)
                            //console.log(item)
                        })
                      })
                      console.log(searchlist)
                      console.log(value)
                      setSearchList(searchlist)
                    } else {
                      setSearchValue('')
                      setTotalSearch(0)
                      setFeatureSearch(0)
                      setAwardSearch(0)
                      setPressSearch(0)
                    }
                  }}
                  //placeholder="Ask away!"
                  type="text"
                  autoFocus
                />
                <img
                  className="pointer"
                  onClick={() => {
                    handleClose()
                  }}
                  src={Icons.CloseIconHLP}
                />      
              </div>
            </ClickAwayListener>
          </DialogContent>
      </Dialog>}
    </div>        
    <Footer />
  </div>    
  )
}

export default DisplayNews
