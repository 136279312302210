import React, { useCallback, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InputAdornment from '@mui/material/InputAdornment'
import { Form, Formik } from 'formik'
import { MobileNumnerSchema, phonePreventText } from '../../../../schema'
import { useDispatch, useSelector } from 'react-redux'

import OnBoardingActions from '../../../../actions/onBoardingAction'
import { Dialog, Grid } from '@mui/material'
import OtpField from 'react-otp-field'
import Link from '@mui/material/Link'

import { useTimer } from 'react-timer-hook'
import moment from 'moment'
import { ReducerModel } from '../../../../reducers/reducerModel'
import { ActionTypes } from '../../../../constants'
import { RootState } from '../../../../reducers'
import { getXResponse, handlePasteMethod, moengageEvent, setXResponse, tagMethodGTM } from '../../../../utils/common'
import ReCAPTCHA from 'react-google-recaptcha'
import ReCaptchaComponent from '../../../../components/ReCaptcha'
import _, { round } from 'lodash'

interface IntialValues {
  phone: string
}

interface Props {
  verifyOTPToShowFullDetails: any
  setShowHeaderText: any
}

const EnterPhone = ({
  verifyOTPToShowFullDetails,
  setShowHeaderText,
}: Props) => {
  const dispatch: Function = useDispatch()

  const {
    phone: inputNumber,
    otpVerifed,
    verifiedPhoneNumber,
  } = useSelector((store: ReducerModel) => store.registerYourInterest)

  const [open, setOpen] = useState(false)
  const [otp, setOtp] = useState('')
  const [otpInvalid, setOtpInvalid] = useState('')
  const [btnClicked, setBtnClicked] = React.useState('')
  const [showContinueButton, setShowContinueButton] = useState(false)
  const [verifyCaptcha, setVerifyCaptcha] = useState(false)
  const [verifyResendCaptcha, setVerifyResendCaptcha] = useState(true)

  const { phone, resendTime, tab, otpAttemps, checkFiveMinutes } = useSelector(
    (store: ReducerModel) => store.onboarding
  )

  const {joinWaitListkey} = useSelector((store : ReducerModel) => store.home.appConfig)

  const initialVal: IntialValues = {
    phone: '',
  }

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: new Date(moment(new Date()).format()),
    onExpire: () => console.warn('onExpire called'),
  })

  const handleClose = () => {
    setOpen(false)
    setOtp('')
    setOtpInvalid('')
    setVerifyResendCaptcha(true)        
    dispatch({
      type: ActionTypes.OTP_NUMBER_ATTEMPS,
      payload: { otpAttemps: 3 },
    })
    setXResponse('')
    //window.location.reload()
  }

  useEffect(() => {
    window.addEventListener('storage', () => {          
      if(!open) {
        if(getXResponse()) {
          setVerifyCaptcha(true)
        } else {
          setVerifyCaptcha(false)
        }  
      }
      if(open) {
        if(getXResponse()) {
          setVerifyResendCaptcha(false)          
        } else {
          setVerifyResendCaptcha(true)          
        }
      }      
    })   
  })

  useEffect(() => {
    var start = new Date().getTime();
    window.scrollTo(0, 0)
    const inputType: any = document.querySelector("input[type='number']")




    inputType?.addEventListener('keydown', function(e:any) {
      if (e.which === 38 || e.which === 40) {
          e.preventDefault();
      }
  });

    setShowHeaderText(false)

    if (inputType) {
      inputType?.addEventListener('keypress', (evt: any) => {
        if (evt.which === 8) {
          return
        }
        if (evt.which < 48 || evt.which > 57) {
          evt.preventDefault()
        }
      })
    }

    return () => {
      var elapsed = new Date().getTime() - start;      

      let scrollPercentage = document.documentElement, 
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';

      let percent = (scrollPercentage[st]||b[st]) / ((scrollPercentage[sh]||b[sh]) - scrollPercentage.clientHeight) * 100;

      moengageEvent('RYI_Page_Viewed', 
        { 
          'Page_URL': window.location.href,
          'Source': window.location.href,
          'Duration': elapsed/1000,
          'Page_Scroll_Depth': round(percent)
        })
    }
  }, [])

  const handleChangeOTP = (otp: string) => {
    setOtpInvalid('')
    setOtp(otp)
  }

  const handleVerifyOTP = (phone: string) => {
    dispatch(
      OnBoardingActions.checkOTP(
        {
          otp,
          phone,
          type: '',
        },
        (success: any) => {
          if (success) {
            console.log({ success })
            setShowContinueButton(true)
            setOtp('')
            setOtpInvalid('')
            setOpen(false)
            dispatch({
              type: ActionTypes.PHONE_NUMBER_RYI,
              payload: { phone, otpVerifed: true, verifiedPhoneNumber: phone },
            })
            verifyOTPToShowFullDetails()
          } else {
            console.log('something went wrong')
          }
        },
        (error: any) => {
          const OtpFields: number = otpAttemps - 1

          console.log({ OtpFields })
          if (error.lockTime === 300000 || OtpFields === 0) {
            restart(new Date(moment().add(error?.resendTime, 'milliseconds').format()))
            setOtp('')
            setOtpInvalid('')
            dispatch({
              type: ActionTypes.CHECK_FIVE_MINUTES,
              payload: { checkFiveMinutes: true },
            })
            dispatch({
              type: ActionTypes.OTP_NUMBER_ATTEMPS,
              payload: { otpAttemps: 3 },
            })
          }

          if (error && error.message) {
            console.log('Yes herer')
            if (OtpFields != 0) setOtpInvalid('Sorry, OTP is invalid.')
            // setWhatsappError('error')
          }
        },
        'RYI'
      ),
      
    )
  }

  const resendOTP = (phone: string) => {
    setOtp('')
    setOtpInvalid
    dispatch(
      OnBoardingActions.requestForOTP(
        { phone },
        (result: any) => {
          console.log({ ressendOtp: result })
          if (result) {
            setXResponse('')
            //restart(new Date(result?.resendTime))
            restart(
              new Date(
                result.resendTime.toString().length < 7
                  ? new Date(
                      moment().add(result.resendTime, 'milliseconds').format()
                    )
                  : new Date(moment().add(180000, 'milliseconds').format())
              )
            )
          }
        },
        () => {}
      )
    )
  }

  const debouncedResendClick = useCallback(
    _.debounce(
      (phone) => {
        resendOTP(phone)
      },
      500,
      { }
    ),
    []
  )

  const handleFromSubmit = (values: any) => {
    // if (btnClicked === 'getOTP') {
      dispatch(
        OnBoardingActions.requestForOTP(
          { phone: values.phone },
          (success: any) => {
            if (success) {
              console.log({ success })
              setOpen(true)
              setXResponse('')
              restart(
                new Date(
                  success.resendTime.toString().length < 7
                    ? new Date(
                        moment()
                          .add(success.resendTime, 'milliseconds')
                          .format()
                      )
                    //: new Date(success.resendTime)
                    : new Date(moment().add(180000, 'milliseconds').format())
                )
              )
            }
          },
          (error: any) => {}
        )
      )
    // } else if (btnClicked === 'continue') {
    //   console.log('Contniue')
    //   // verifyOTPToShowFullDetails()
    // }
  }

  const debouncedClick = useCallback(
    _.debounce(
      (values) => {
        handleFromSubmit(values)
      },
      500,
      { }
    ),
    []
  )

  console.log('otpInvalid', otpInvalid)

  return (
    <>
      <p className="subText mt-20 mb-24">
      Please enter your Mobile No. to proceed
      </p>

      <Formik
        validationSchema={MobileNumnerSchema}
        initialValues={initialVal}
        enableReinitialize
        onSubmit={(values) => {
          //handleFromSubmit(values)
          debouncedClick(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          isValid,
          dirty,
          setFieldValue,
          handleSubmit,
        }) => {
          const { phone } = values

          handleBlur = () => {
            if(phone.toString().length === 10) {
              moengageEvent('RYI_Mobile_Number_Fill', {'Mobile_Number': phone})
            }
          }

          return (
            // <Form>
              <form 
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit
              
              }} 
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                }
              }}>
              <TextField
                id="mobileNumber"
                placeholder="Phone Number"
                name="phone"
                autoComplete="off"
                type="number"
                onWheel={(e) => document?.activeElement?.blur()}

                value={phone}
                onFocus={() => {
                  joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Mobile Number` , "Click" , "Enter Mobile Number")
                  :tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Mobile Number` , "Click" , "Enter Mobile Number");
                }}
                onPaste={(e) => handlePasteMethod(e, setFieldValue , "phone")}
                onChange={(e) => {
                  if (e.target.value.length === 11) return
                  setFieldValue('phone', e.target.value)
                }}
                onBlur={handleBlur}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                onKeyDown={(e: any) => {
                  ;(phonePreventText.includes(e.key) ||
                    (e.key === 'ArrowUp' || e.key === 'ArrowDown')) &&
                    e.preventDefault()
                }}
                className="w-100"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="cc-prop">
                      +91
                    </InputAdornment>
                  ),
                  endAdornment:
                    showContinueButton && phone === verifiedPhoneNumber ? (
                      <CheckCircleIcon className="success ml-12" />
                    ) : (
                      ''
                    ),
                }}
              />
              {!showContinueButton || phone != verifiedPhoneNumber ? (
                <>
                  {/* {!open && <ReCaptchaComponent />} */}
                  <button
                    //disabled={!((phone.toString().length === 10) && (verifyCaptcha) && (!open))}
                    disabled={!((phone.toString().length === 10) && (!open))}
                    className="btn btn-primary w-100 mt-30"
                    type="submit"
                    onClick={() => {
                      joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Get OTP` , "Click" , "Get OTP")
                      : tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Get OTP` , "Click" , "Get OTP");
                      setBtnClicked('getOTP')
                      handleSubmit()
                      // setOpen(true)
                    }}
                  >
                    Get OTP
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-primary w-100 mt-30"
                  type="submit"
                  onClick={() => {
                    setBtnClicked('continue')
                    // setOpen(true)
                  }}
                >
                  Continue
                </button>
              )}

           
              </form>
            // </Form>
          )
        }}
      </Formik>


         {/* ************* OTP DAILOG  **************/}

         <Dialog
                open={open}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirmation-dialog white-theme-dialog"
                fullWidth
                maxWidth="sm"
              >
                   <form
                     onSubmit={(e) => {
                    e.preventDefault()
                    if(otp.length === 4) {
                      console.log("Enter")
                      // handleLogin()
                    }
                  }}
                  >
                <p className="mb-20 ">
                  A verification OTP has been sent to {phone}
                </p>
                <p className="small subText mb-20">
                  Please verify to ensure that we can reach you
                </p>
                <div className="white">
                  <div className="flex al-center js-start">
                    <OtpField
                      value={otp}
                      onChange={handleChangeOTP}
                      numInputs={4}
                      onChangeRegex={/^([0-9]{0,})$/}
                      autoFocus
                      inputProps={{
                        className: `otp-field__input__register  ${
                          otpInvalid && 'wrong_otp'
                        }`,
                        disabled: checkFiveMinutes ? true : false,
                        type: 'tel',
                      }}
                    />
                  </div>

                  <div className="flex al-center js-between">
                    <p className="ex-small danger mt-10">
                    {otpInvalid && otpAttemps ? ' Sorry, OTP is invalid' : ''}
                    </p>
                    {otpAttemps < 3 && (
                      <p className="ex-small subText mt-10">
                        {otpAttemps} attempts remaining{' '}
                      </p>
                    )}
                  </div>
                </div>
                {/* {!isRunning && <ReCaptchaComponent />} */}
                <div className="flex al-center js-between mt-20 mb-32">
                  <p className="ex-small subText">
                    Didn’t receive OTP?{' '}
                    <Link
                      className={`bold resend-link  ${
                        //(isRunning || verifyResendCaptcha) ? 'disable' : ''
                        (isRunning) ? 'disable' : ''
                      }`}
                      onClick={() => {
                       joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Resend OTP` , "Click" , "Resend OTP") :
                       tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Resend OTP` , "Click" , "Resend OTP");
                        
                        debouncedResendClick(phone)}}
                    >
                      Resend OTP
                    </Link>
                  </p>
                  {isRunning && (
                    <p className="ex-small subText">
                      {minutes < 10 ? '0' + minutes : minutes}:
                      {seconds < 10 ? '0' + seconds : seconds}
                    </p>
                  )}
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <button
                      className="btn w-100 btn-white-cancel"
                      onClick={() => {
                        joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Cancel` , "Click" , "Cancel") :
                        tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Cancel` , "Click" , "Cancel");
                       
                        handleClose()}}
                        type="button"
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item xs={6}>
                    <button
                    type="submit"
                      className="btn w-100 btn-primary"
                      onClick={() =>{
                        joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Verify` , "Click" , "Verify") :
                        tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Verify` , "Click" , "Verify");
                        
                        handleVerifyOTP(phone)}}
                      disabled={otp.length !== 4 ? true : false || !!otpInvalid}
                    >
                      Verify
                    </button>
                  </Grid>
                </Grid>
                </form>
              </Dialog>
    </>
  )
}

export default EnterPhone
