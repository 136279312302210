import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import Icons from '../../../../utils/icons'
import { ReducerModel } from '../../../../reducers/reducerModel'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { phonePreventText, registerYourInterest } from '../../../../schema'
import {
  postGeoLocaiton,
  resetRYI,
  registerInterestUser,
} from '../../../../actions/registerInterest'
import { ActionTypes } from '../../../../constants'
import { useHistory } from 'react-router-dom'

import { handlePasteMethod, moengageEvent, tagMethodGTM } from '../../../../utils/common'
import { setFlow } from '../../../../actions/pinCodeActions';
import { useLocation } from "react-router-dom";




interface IntialValues {
  firstName: string
  lastName: string
  phone: string
  email: string
  receiveNotifications?: boolean
  whatsAppNo?: string
  city: string
  state: string
  teams?: boolean
  interestedIn: any
  pinCode: string
  event: string
}

interface PostalApiResponse {
  PostOffice: {
    Name: string;
    District: string;
    State: string;
  }[];
  Status: string;
  Message?: string;
}

const FullDetail = ({validateUserToken, setShowHeaderText, setShowFullDetail }: any) => {
  const history = useHistory()
  const { phone, otpVerifed } = useSelector(
    (store: ReducerModel) => store.registerYourInterest
  )
  
  const [userPincode, setUserPincode] = useState('');
  const { pincode, isBookNow } = useSelector((store: ReducerModel) => store.pincode)
  const [CityOnly, setCity] = useState('');
  const [StateOnly, setState] = useState('');
  const [pinCodeOnly, setPinCodeOnly] = useState('');

  useEffect(() => {
    if (pincode) {
      console.log('Pincode from previous screen:', pincode);

      const regex = /^(.+),\s+(.+),\s+\((\d+)\)$/;
      const match = pincode.match(regex);

      if (match) {
        setCity(match[1]); 
        setState(match[2]); 
        setPinCodeOnly(match[3]); 
      } else {
        console.log('Pincode format is invalid');
      }
    }
  }, [pincode]);
  const [userCity, setUserCity] = useState('')
  const [userState, setUserState] = useState('')
  const [confirmationScreen, setConfirmationScreen] = useState(false)

  const {appConfig} = useSelector((store: ReducerModel) => store.home)


  const ryiUserData = useSelector((store: ReducerModel) => store.registerYourInterest)
  const {joinWaitListkey} = useSelector((store : ReducerModel) => store.home.appConfig)
  const [confirmationMessage, setConfirmationMessage] = useState(false)
  const [displayMessage, setDisplayMessage] = useState('')

  const dispatch: Function = useDispatch()

  function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      const checkIsMobile = () => setIsMobile(window.innerWidth <= 576);
      checkIsMobile();
      window.addEventListener('resize', checkIsMobile);
      return () => window.removeEventListener('resize', checkIsMobile);
    }, []);
  
    return isMobile;
  }

  const isMobile = useIsMobile();
  const gridProps =  isMobile ? { xs: 12 } : { xs: 6 };

  // useEffect(() => {
  //   if (window.location.pathname === '/book-now') {
  //     dispatch(setFlow(true)); // Set isBookNow to true if it's the "book now" flow
  //   } else {
  //     dispatch(setFlow(false)); // Otherwise, set it to false
  //   }
  // }, [dispatch, window.location.pathname]);


  console.log("Book now is"+ setFlow);

  const getAddressFromCoords = async (lat: number, long: number) => {
    try {
      // Using Nominatim OpenStreetMap service for reverse geocoding
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`
      );
      const data = await response.json();
      
      // Extract postal code from the response
      const postalCode = data.address?.postcode || '';
      return {
        pincode: postalCode,
        city: data.address?.city || data.address?.town || '',
        state: data.address?.state || ''
      };
    } catch (error) {
      console.error('Error getting address:', error);
      return { pincode: '', city: '', state: '' };
    }
  };
  const [pincodeError, setPincodeError] = useState('');
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const { pinCodeValue } = useSelector((store: ReducerModel) => store.ecBooking);
  const intialVal: IntialValues = {
    city: CityOnly,
    state: StateOnly,
    teams: false,
    interestedIn: [],
    receiveNotifications: true,
    email: ryiUserData?.email || '',
    phone: ryiUserData?.phone || phone,
    lastName: ryiUserData?.lastName || '',
    firstName: ryiUserData?.firstName || '',
    whatsAppNo: ryiUserData?.whatsAppNo || phone,
    pinCode:  pinCodeOnly || '',
    event: isBookNow ? 'Book Now' : 'Book Test Ride',
  }

  const handlePincodeLookup = async (
    pincode: string, 
    setFieldValue: (field: string, value: any) => void
  ) => {
    // Reset previous error and set loading state
    setPincodeError('')
    setPincodeLoading(true)
  
    // Validate pincode length
    if (pincode.length !== 6) {
      setPincodeError('Pincode must be 6 digits')
      setPincodeLoading(false)
      return
    }
  
    // Perform lookup for both Book Now and non-Book Now flows
    try {
      // Call Postal PIN Code API
      const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      const data: PostalApiResponse[] = await response.json();
  
      // Check API response status and data
      if (data[0]?.Status === 'Success' && data[0]?.PostOffice?.length > 0) {
        const firstPostOffice = data[0].PostOffice[0];
        const city = firstPostOffice.District;
        const state = firstPostOffice.State;
        
  
        if (isBookNow) {
          // For Book Now flow, update only the city
          setCity(city)
          setFieldValue('city', city)
        } else {
          // For non-Book Now flow, update all fields as before
          setCity(city)
          setState(state)
          setPinCodeOnly(pincode)
  
          setFieldValue('city', city)
          setFieldValue('state', state)
          setFieldValue('pinCode', pincode)
        }

      } else {
        // No details found
        setPincodeError('Invalid pincode')
        
      }
    } catch (error) {
      console.error('Pincode lookup error:', error);
      setPincodeError('Unable to fetch pincode details')
      
      if (isBookNow) {
        // For Book Now flow, reset only city
        setCity('')
        setFieldValue('city', '')
      } else {
        // For non-Book Now flow, reset all fields
        setCity('')
        setState('')
        setPinCodeOnly('')
  
        setFieldValue('city', '')
        setFieldValue('state', '')
        setFieldValue('pinCode', '')
      }
    } finally {
      // Always reset loading state
      setPincodeLoading(false)
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)



    const inputType: any = document.querySelector("#whatsAppNo")
    console.log("inputType",inputType)

    inputType?.addEventListener('keydown', function(e:any) {
      if (e.which === 38 || e.which === 40) {
          e.preventDefault();
      }
  });

  if (inputType) {
    inputType?.addEventListener('keypress', (evt: any) => {
      if (evt.which === 8) {
        return
      }
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault()
      }
    })
  }



    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude
      let long = position.coords.longitude
      dispatch(
        postGeoLocaiton(
          {
            longitude: long.toString(),
            latitude: lat.toString(),
          },

          (response: any) => {
            const { city, state } = response
            joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Help us know you better` , "Click" , {city}) 
            : tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Help us know you better` , "Click" , {city});
            
            joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Help us know you better` , "Click" , {state})
            : tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Help us know you better` , "Click" , {state}); 
            // console.log(city ,state)
         
            setUserCity(city)
            setUserState(state)
          },

          () => { }
        )
      )
    })

    //  RESET RYI REDUCER ON UNMOUNT COMPONENT
    return () => {      
      dispatch({
        type: ActionTypes.RESET_ONBOARDING
      })
      dispatch(resetRYI())
    }
  }, [])

  const handleSubmit = (values: IntialValues) => {


    delete values.teams
    !values.receiveNotifications && delete values.whatsAppNo
    delete values.receiveNotifications
    dispatch(
      registerInterestUser(
        { ...values, interestedIn: "Buying the AERA" },
        (data: any) => {
          window.scrollTo(0, 0)
          setShowHeaderText(true)
          setConfirmationScreen(true)
          setConfirmationMessage(true)
          setDisplayMessage(data.message)
          dispatch({
            type: ActionTypes.RESET_ONBOARDING,
          })
          dispatch(resetRYI())
        },

        (error: any) => {
          if (error.message === `Given phone ${values.phone} already exists` || 
          error.message === `You are already in our waitlist. We will keep you posted about latest update`
          ) {
            window.scrollTo(0, 0)
            setConfirmationScreen(true)
            setConfirmationMessage(true)
            setShowHeaderText(true)
            dispatch({
              type: ActionTypes.RESET_ONBOARDING,
            })
            dispatch(resetRYI())
          }
        }
      )
    )
  }

  const showMessage = () => {
    let message:any = ''
    if (joinWaitListkey) {
      if (confirmationMessage) {
        message = displayMessage
      }else {
        message = displayMessage
      }
      message = <span>{message} <br/> Hang in there, we'll get back to you soon!</span>
    }else {
      if (confirmationMessage) {
        message = displayMessage
      }else {
        message = displayMessage
      }
    }
    return message
  }
  return (
    <>
      {!confirmationScreen ? (
        <>
          <p className="small subText mt-20 mb-24">Help us know you better</p>
          <Formik
            validationSchema={registerYourInterest}
            initialValues={{ ...intialVal, city: CityOnly, state: StateOnly ,  interestedIn:"Buying the AERA"}}
            enableReinitialize
            onSubmit={(values) => {
              joinWaitListkey ? tagMethodGTM(`Join Waitlist Form`, `Matter - Join Waitlist - Help us know you better - Submit`, "Click", "Submit", {
                mobile_number: values.phone,
                first_name : values.firstName,
                last_name: values.lastName,
                state: StateOnly,
                city: CityOnly,
                email :values.email,
                pinCode: values.pinCode,
                // interested_in: values.interestedIn.join(','),
                important_updates_on_whatsapp : values.whatsAppNo,
              }) : tagMethodGTM(`Register Your Interest Form`, `Matter - Register Your Interest - Help us know you better - Submit`, "Click", "Submit", {
                mobile_number: values.phone,
                first_name : values.firstName,
                last_name: values.lastName,
                state: StateOnly,
                city: CityOnly,
                email :values.email,
                pinCode: values.pinCode,
                // interested_in: values.interestedIn.join(','),
                important_updates_on_whatsapp : values.whatsAppNo,
              });
              moengageEvent('Partnership_Let’s_Get_To_Know_You_Form_Fill', {'First_Name': values.firstName,
                'Last_Name': values.lastName,
                'Email_ID': values.email,
                'Select_State': StateOnly,
                'City': CityOnly
              })
              handleSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values,
              isValid,
              dirty,
              setFieldValue,
              handleSubmit,
            }) => {
              const {
                firstName,
                lastName,
                phone,
                email,
                receiveNotifications,
                whatsAppNo,
                city,
                state,
                teams,
                pinCode,
                // interestedIn,
              } = values

              console.log('Before', values)

              console.log('Errors', errors)

              return (
<Form style={pincode && isMobile ? { marginLeft: '-48px' } : {}}>
<Grid container spacing={3}>
  <Grid item xs={12}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          id="mobileNumber"
          placeholder="Phone Number"
          name="phone"
          type="number"
          className="w-100"
          onWheel={(e) => document?.activeElement?.blur()}
          disabled
          value={phone}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="cc-prop">
                +91
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <img src={Icons.checkIconGreen} style={{ 
                  color: '#22C55E',
                  width: '24px',
                  height: '24px',
                  flexShrink: 0
                }} />
              </InputAdornment>
            )
      }}
        />
      </Grid>
    </Grid>
  </Grid>
  <Grid item xs={12}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          placeholder="First Name"
          name="firstName"
          value={firstName}
          autoComplete="off"
          className="w-100"
          onBlurCapture={() => {
            joinWaitListkey 
              ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better`, "Click", "Enter First Name")
              : tagMethodGTM(`Join Waitlist`, `Matter - Register Your Interest - Help us know you better`, "Click", "Enter First Name");
          }}
          inputProps={{ maxLength: 25 }}
          onChange={(e) => handleChange(e)}
          onBlur={handleBlur}
          error={errors.firstName === ' ' ? false : Boolean(touched.firstName && errors.firstName)}
          helperText={errors.firstName === ' ' ? false : touched.firstName && errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          placeholder="Last Name"
          name="lastName"
          value={lastName}
          autoComplete="off"
          className="w-100"
          onBlurCapture={() => {
            joinWaitListkey 
              ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better`, "Click", "Enter Last Name")
              : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better`, "Click", "Enter Last Name");
          }}
          onChange={(e) => handleChange(e)}
          inputProps={{ maxLength: 25 }}
          onBlur={handleBlur}
          error={errors.lastName === ' ' ? false : Boolean(touched.lastName && errors.lastName)}
          helperText={errors.lastName === ' ' ? false : touched.lastName && errors.lastName}
        />
      </Grid>
    </Grid>
  </Grid>

  <Grid item xs={12}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
      {/* <TextField
        placeholder="Pincode*"
        name="pincode"
        value={pinCodeOnly} 
        className="w-100"
        disabled={isBookNow} 
        autoComplete="off"
        type="text"  
        onChange={(e) => setPinCodeOnly(e.target.value)}
        InputProps={{
          inputProps: { 
            maxLength: 6,
            placeholder: "Pincode*"  
          }
        }}
        onBlur={handleBlur}
        error={errors.pinCode === ' ' ? false : Boolean(touched.pinCode && errors.pinCode)}
        helperText={errors.pinCode === ' ' ? false : touched.pinCode && errors.pinCode}
      /> */}

<TextField
  placeholder="Pincode*"
  name="pincode"
  value={pinCodeOnly} 
  className="w-100"
  disabled={isBookNow} 
  autoComplete="off"
  type="text"  
  onChange={(e) => {
    const newPincode = e.target.value;
    setPinCodeOnly(newPincode);
    if (!isBookNow && newPincode.length === 6) {
      handlePincodeLookup(newPincode, setFieldValue);
    }
  }}
  InputProps={{
    inputProps: { 
      maxLength: 6,
      placeholder: "Pincode*"  
    }
  }}
  onBlur={(e) => {
    handleBlur(e);
    if (!isBookNow && e.target.value.length === 6) {
      handlePincodeLookup(e.target.value, setFieldValue);
    }
  }}
  error={errors.pinCode === ' ' ? false : Boolean(touched.pinCode && errors.pinCode)}
  helperText={errors.pinCode === ' ' ? false : touched.pinCode && errors.pinCode}
/>
      </Grid>
    </Grid>
  </Grid>

  <Grid item xs={12}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          placeholder="City"
          name="city"
          value={isBookNow ? city : CityOnly}
          className="w-100"
          disabled
          autoComplete="off"
          onBlurCapture={() => {
            joinWaitListkey 
              ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better`, "Click", values.city)
              : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better`, "Click", "City");
          }}
          inputProps={{ maxLength: 40 }}
          onBlur={handleBlur}
          error={errors.city === ' ' ? false : Boolean(touched.city && errors.city)}
          helperText={errors.city === ' ' ? false : touched.city && errors.city}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          placeholder="State"
          name="state"
          value={StateOnly}
          className="w-100"
          disabled
          onBlurCapture={() => {
            joinWaitListkey 
              ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better`, "Click", values.state)
              : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better`, "Click", "State");
          }}
          autoComplete="off"
          inputProps={{ maxLength: 40 }}
          onBlur={handleBlur}
          error={errors.state === ' ' ? false : Boolean(touched.state && errors.state)}
          helperText={errors.state === ' ' ? false : touched.state && errors.state}
        />
      </Grid>
    </Grid>
  </Grid>

  <Grid item xs={12}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          className="w-100"
          placeholder="Email ID"
          name="email"
          value={email}
          onBlurCapture={() => {
            joinWaitListkey 
              ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better`, "Click", "Email")
              : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better`, "Click", "Email");
          }}
          onChange={(e) => handleChange(e)}
          autoComplete="off"
          onKeyDown={(e) => [' '].includes(e.key) && e.preventDefault()}
          onBlur={handleBlur}
          error={errors.email === ' ' ? false : Boolean(touched.email && errors.email)}
          helperText={errors.email === ' ' ? false : touched.email && errors.email}
        />
      </Grid>
    </Grid>
  </Grid>

  <Grid item xs={12} sm={6}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className="flex al-start mt-20">
          <Checkbox
            name="receiveNotifications"
            checked={receiveNotifications}
            onChange={(e) => {
              joinWaitListkey 
                ? !receiveNotifications 
                  ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better - Updates on WhatsApp - Checkbox`, "Click", `Accepted`) 
                  : ''
                : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better - Updates on WhatsApp - Checkbox`, "Click", `Accepted`);
              handleChange(e);
              !receiveNotifications ? moengageEvent('RYI_Get_Update_WhatsApp_CTA', {
                'Select' : 'Receive Notifications on WhatsApp'
              }) : moengageEvent('RYI_Get_Update_WhatsApp_CTA', {
                'Unselect' : `Don't Receive Notifications on WhatsApp`
              })
            }}
            checkedIcon={<img src={Icons.CheckboxActiveIcon} />}
            icon={<img src={Icons.CheckboxIcon} />}
          />
          <p className="small subText">
            Get important updates on WhatsApp
          </p>
        </div>
          <TextField
            placeholder="Phone Number"
            type="number"
            className="w-100 mb-10 mt-8"
            onWheel={(e) => document?.activeElement?.blur()}
            id="whatsAppNo"
            name="whatsAppNo"
            value={whatsAppNo}
            onPaste={(e) => handlePasteMethod(e, setFieldValue, "whatsAppNo")}
            onBlurCapture={() => {
              joinWaitListkey 
                ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better - Updates on WhatsApp`, "Click", "Checkbox")
                : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better - Updates on WhatsApp`, "Click", "Checkbox");
                moengageEvent('RYI_Whatsapp_Number_Fill', {
                  'Whatsapp_Number_Fill' : whatsAppNo
                })
            }}
            onChange={(e) => {
              if (e.target.value.length === 11) return;
              setFieldValue('whatsAppNo', e.target.value);
            }}
            onKeyDown={(e) => phonePreventText.includes(e.key) && e.preventDefault()}
            onBlur={handleBlur}
            error={errors.whatsAppNo === ' ' ? false : Boolean(touched.whatsAppNo && errors.whatsAppNo)}
            helperText={errors.whatsAppNo === ' ' ? false : touched.whatsAppNo && errors.whatsAppNo}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="cc-prop">
                  +91
                </InputAdornment>
              )
            }}
          />
      </Grid>
      <Grid item xs={12}>
        <div className="flex al-start js-start subText">
          <Field
            as={Checkbox}
            type="checkbox"
            name="teams"
            onChange={(e) => {
              joinWaitListkey 
                ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better - Disclaimer`, "Click", "Disclaimer-Checked")
                : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better - Disclaimer`, "Click", "Disclaimer-Checked");
              handleChange(e);
              !(isValid && dirty) ? moengageEvent('RYI_Agree_TnC_CTA', {
                'Select' : 'Agree T&C'
              }) : moengageEvent('RYI_Agree_TnC_CTA', {
                'Unselect' : 'Unchecked T&C'
              })  
            }}
            color="primary"
            checkedIcon={<img src={Icons.CheckboxActiveIcon} />}
            icon={<img src={Icons.CheckboxIcon} />}
          />
          <p className="small">
            By clicking Submit, you agree to our{' '}
            <Link href="/terms-and-conditions" className="underline primary bold" target='_blank'>
              T&C of Use
            </Link>{' '}
            &{' '}
            <Link href="/privacy-policy" className="underline primary bold" target='_blank'>
              Privacy Policy
            </Link>
          </p>
        </div>
      </Grid>
      <Grid  item {...gridProps} >
        
        <button
          className="btn btn-gray w-100"
          // disabled={validateUserToken}
          type="button"
          onClick={(e) => {
            joinWaitListkey 
              ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better - Back`, "Click", "Help us know you better - Back")
              : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better - Back`, "Click", "Help us know you better - Back");
            dispatch(resetRYI());
            setShowFullDetail(false);
            pincode ? history.push('/booknow') : history.push('/booknow');

          }}
        >
          Back
        </button>
      </Grid>
      <Grid  item {...gridProps}>
        <button
          className="btn btn-primary w-100"
          type="submit"
          disabled={!(isValid && dirty)}
          onClick={() => {
            handleSubmit();
            joinWaitListkey 
              ? tagMethodGTM(`Join Waitlist`, `Matter - Join Waitlist - Help us know you better - Submit`, "Click", "Submit")
              : tagMethodGTM(`Register Your Interest`, `Matter - Register Your Interest - Help us know you better - Submit`, "Click", "Submit");
            moengageEvent('RYI_Submit_CTA_Click', {
                'Click' : true
              })  
          }}
        >
          Submit
        </button>
      </Grid>
    </Grid>
  </Grid>
</Grid>
</Form>
              )
            }}
          </Formik>
        </>
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item md={12} lg={6}>
              <img src={Icons.CheckGreen} className="info-icon" alt="" style={pincode && isMobile ? { marginLeft: '-24px' } : {}}/>
              <h2 className="monument mb-28" style={pincode && isMobile ? { marginLeft: '-24px' } : {}}>
                <span className="primary">Thank you</span> for <br />
                reaching out
              </h2>
              <p className="large subText mb-24" style={pincode && isMobile ? { marginLeft: '-24px' } : {}}>
                {showMessage()}
              </p>
            </Grid>
            <Grid item xs={12} lg={9} style={pincode && isMobile ? { marginLeft: '-24px' } : {}}>
              <Grid container spacing={3} style={pincode && isMobile ? { marginLeft: '-24px' } : {}}>
                <Grid item xs={12} sm={6}>
                  <button
                    className="btn btn-primary w-100 mb-40"
                    onClick={() => {
                      history.push('/motorbike')
                      moengageEvent('RYI_Continue_Exploring_CTA_Click', {
                        'Click' : true
                      })
                    }}
                    style={pincode && isMobile ? { marginLeft: '-48px' } :  {}}
                  >
                    Continue Exploring
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default FullDetail