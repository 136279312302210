import Icons from '../utils/icons'
import Images from '../utils/images'
import { AeraDetail, AERA_TYPE } from './model'

const aera5000PlueSummary = [
  {
    id: '1',
    title: 'Matter Care Package',
  },
  {
    id: '2',
    title: 'On-Board Charger',
  },
  {
    id: '3',
    title: 'Charging Cable With Universal Charging Capability',
  },
  {
    id: '4',
    title: 'Lifestyle Package',
  },
  {
    id: '5',
    title: 'Matter Care+ Package',
  },
  {
    id: '6',
    title: 'Connected Package',
  },
]

const aera5000Summary = [
  {
    id: '1',
    title: 'Matter Care Package',
  },
  {
    id: '2',
    title: 'On-Board Charger',
  },
  {
    id: '3',
    title: 'Charging Cable With Universal Charging Capability',
  },
]

const aera5000PlusAvailableColor = [
  {
    id: '1',
    colorName: 'Cosmic Black',
    //bikeImage: Images.BlackBike, {old assest}
    bikeImage: Images.BlackBikeChangedAssestNew,
    thumbnailBikeImg: Images.BlackBikeThumb,
    bikeVideo: Images.View360_black5000_plus,
    bikeVideoMP4: Images.View360_blackMP45000_plus,
    colorDescription:
      'High-gloss metallic shade brimmed with a touch a bright Aqua, the electrifying eagle of street riders.',
    colorPath: Images.BlackTile,
    alt: 'Black Color Icon',
    bikeDirectoryName: 'Black5000plus'
  },
  {
    id: '2',
    colorName: 'Cosmic Blue',
    //bikeImage: Images.BlueBike, {old assest}
    bikeImage: Images.BlueBikeChangedAssestNew,
    thumbnailBikeImg: Images.BlueBikeThumb,
    bikeVideo: Images.View360_blue5000_plus,
    bikeVideoMP4: Images.View360_blueMP45000_plus,
    colorDescription:
      "Trailblazing yellow accents embossed on the sensational blue, if this ain't trendsetting, what is?!",
    colorPath: Images.BlueTile,
    alt: 'Blue Color Icon',
    bikeDirectoryName: 'Blue5000plus'
  },

  {
    id: '3',
    colorName: 'Glacier White',
    //bikeImage: Images.GreyBike, {old assest}
    bikeImage: Images.WhiteBikeChangedAssestNew,
    thumbnailBikeImg: Images.GreyBikeThumb,
    bikeVideo: Images.View360_white5000_plus,
    bikeVideoMP4: Images.View360_whiteMP45000_plus,
    colorDescription:
      'Exquisiteness of serene white dappled with cherry red, a standalone model worth just perfection!',
    colorPath: Images.GreyTile,
    alt: 'Glacier White Color Icon',
    bikeDirectoryName: 'White5000plus'
  },
  {
    id: '4',
    colorName: 'Blaze Red',
    //bikeImage: Images.RedBike, {old assest}
    bikeImage: Images.RedBikeChangedAssestNew,
    thumbnailBikeImg: Images.RedBikeThumb,
    bikeVideo: Images.View360_red5000_plus,
    bikeVideoMP4: Images.View360_redMP45000_plus,
    colorDescription:
      'An impression of red, breathtaking and passionate, this white-hued model is a signature of boldness!',
    colorPath: Images.RedTile,
    alt: 'Red Color Icon',
    bikeDirectoryName: 'Red5000plus'
  },
  {
    id: '5',
    colorName: 'Nord Grey',
    //bikeImage: Images.YellowBike, {old assest}
    bikeImage: Images.GrayBikeChangedAssestNew,
    thumbnailBikeImg: Images.YellowBikeThumb,
    bikeVideo: Images.View360_gray5000_plus,
    bikeVideoMP4: Images.View360_grayMP45000_plus,
    colorDescription:
      'Elegance re-styled with sophisticated tones of grey and greens that reflect nothing but dominance!',
    colorPath: Images.YellowTile,
    alt: 'Yellow Color icon',
    bikeDirectoryName: 'Grey5000plus'
  },
]
const aera5000eAvailableColor = [
  // {
  //   id: '1',
  //   colorName: 'Cosmic Black',
  //   bikeVideo: Images.View360_black5000,
  //   bikeVideoMP4: Images.View360_blackMP45000,
  //   colorDescription:
  //     'High-gloss metallic shade brimmed with a touch a bright Aqua, the electrifying eagle of street riders.',
  //   colorPath: Images.BlackTile,
  //   //bikeImage: Images.BlackBike1, {old assest}
  //   bikeImage: Images.BlackBikeChangedAssest,
  //   thumbnailBikeImg: Images.BlackBike1Thumb,
  //   alt: 'Black Color Icon',
  //   bikeDirectoryName: 'Black5000'
    
  // },

  {
    id: '1',
    colorName: 'Cosmic Blue',
    //bikeImage: Images.BlueBike1, {old assest}
    bikeImage: Images.BlueBikeChangedAssest,
    bikeVideo: Images.View360_blue5000,
    bikeVideoMP4: Images.View360_blueMP45000,
    colorDescription:
      "Trailblazing yellow accents embossed on the sensational blue, if this ain't trendsetting, what is?!",
    colorPath: Images.BlueTile,
    bikeDirectoryName: 'Blue5000',
    alt : 'Blue Color Icon',
    thumbnailBikeImg: Images.BlueBike1Thumb,
  },
]

const aera5000AvailableColor = [
  {
    id: '1',
    colorName: 'Cosmic Black',
    bikeVideo: Images.View360_black5000,
    bikeVideoMP4: Images.View360_blackMP45000,
    colorDescription:
      'High-gloss metallic shade brimmed with a touch a bright Aqua, the electrifying eagle of street riders.',
    colorPath: Images.BlackTile,
    //bikeImage: Images.BlackBike1, {old assest}
    bikeImage: Images.BlackBikeChangedAssest,
    thumbnailBikeImg: Images.BlackBike1Thumb,
    alt: 'Black Color Icon',
    bikeDirectoryName: 'Black5000'
    
  },

  {
    id: '2',
    colorName: 'Cosmic Blue',
    //bikeImage: Images.BlueBike1, {old assest}
    bikeImage: Images.BlueBikeChangedAssest,
    bikeVideo: Images.View360_blue5000,
    bikeVideoMP4: Images.View360_blueMP45000,
    colorDescription:
      "Trailblazing yellow accents embossed on the sensational blue, if this ain't trendsetting, what is?!",
    colorPath: Images.BlueTile,
    bikeDirectoryName: 'Blue5000',
    alt : 'Blue Color Icon',
    thumbnailBikeImg: Images.BlueBike1Thumb,
  },
]

const availableAeras: Array<AeraDetail> = [
  {
    range: 125,
    power: '10.5',
    productId: AERA_TYPE.FIVE_THOUSHAND_e,
    acceleration: '<6',
    batteryCapacity: '5',
    availableColors: aera5000eAvailableColor,
    bikeModalImage: Images.Aera5000,
    discountApplied: '0',
    bikeTotalPrice: '0',
    description:
      'Awesome Pick! A Futuristic model of muscularity edged with technology!',
    emiStartPrice: '',
    emiAmount: '0',
    prebookAmount : '1',
    productPrice: '0',
    productName: 'Aera 5000e',
  },
  {
    range: 125,
    power: '11.5',
    productId: AERA_TYPE.FIVE_THOUSHAND,
    acceleration: '<6',
    batteryCapacity: '5',
    availableColors: aera5000AvailableColor,
    bikeModalImage: Images.Aera5000,
    discountApplied: '0',
    bikeTotalPrice: '0',
    description:
      'Awesome Pick! A Futuristic model of muscularity edged with technology!',
    emiStartPrice: '',
    emiAmount: '0',
    prebookAmount : '1',
    productPrice: '0',
    productName: 'Aera 5000',
  },
  {
    range: 125,
    power: '11.5',
    productId: AERA_TYPE.FIVE_THOUSHAND_PLUS,
    acceleration: '<6',
    batteryCapacity: '5',
    emiAmount: '0',
    prebookAmount : '1',
    bikeTotalPrice: '0',
    bikeModalImage: Images.Aera5000Plus,
    description:
      'Unbeatable it is! Immersive Connectivity that embraces your street lifestyle.',
    availableColors: aera5000PlusAvailableColor,
    emiStartPrice: '4749',
    productPrice: '0',
    productName: 'Aera 5000+',
    discountApplied: '0',
  },
]

const footerBannerRoutes = [
  {
    title: 'Grab your Aera!',
    description:
      'Grab your rider’s pass to the 22nd century’s dynamic motorbiking experience! Get your Hands-on the priority access to AERA,  now!',
    icon: Icons.PrebookIcon,
    routes: [
      { title: 'Join Waitlist', route: '/register-your-interest' },
      // { title: 'Register Your Interest', route: '/register-your-interest' },
    ],
  },
  {
    title: 'About us',
    description:
      'From the sketch board to a solid mobility solution, dive into the ethos that drives Matter.',
    icon: Icons.AboutUsIcon,
    routes: [{ title: 'Who We Are', route: '/who-we-are' },{ title: 'Innovation Hub', route: '/innovation-hub' },],
  },
  {
    title: 'Support',
    description: `Get the answers you need for your electric vehicle journey. We're here for you!`,
    icon: Icons.SupportIcon,
    routes: [{ title: 'FAQ’s', route: '/faq' },{title : 'Support',route : '/support'}],
  },
  {
    title: 'Partner with us!',
    description:
      'Join us in shaping the future of electric vehicles in India, as we revolutionize the industry with our pioneering solutions.',
    icon: Icons.PartnerWithUsIcon,
    routes: [
      { title: 'Channel Partner', route: '/become-a-partner' },
      // { title: 'Become Supplier', route: 'BecomeAPartnerComponent' },
    ],
  },
]

const hyperLocalFooterRoutes = [

 {
    title: 'Matter Stream',
    description:
      'Stream all the latest updates on Matter, AERA & the EV industry in one place',
    icon: Icons.BlogIcon,
    routes: [
      { title: 'Blogs', route: '/blogs' },
    ],
  },
  {
    title: 'About us',
    description:
      'From the sketch board to a solid mobility solution, dive into the ethos that drives Matter.',
    icon: Icons.AboutUsIcon,
    routes: [{ title: 'Who We Are', route: '/who-we-are' },{ title: 'Innovation Hub', route: '/innovation-hub' },],
  },
  {
    title: 'Support',
    description: `Get the answers you need for your electric vehicle journey. We're here for you!`,
    icon: Icons.SupportIcon,
    routes: [{ title: 'FAQ’s', route: '/faq' },{title : 'Support',route : '/support'}],
  },
  {
    title: 'Partner with us!',
    description:
      'Join us in shaping the future of electric vehicles in India, as we revolutionize the industry with our pioneering solutions.',
    icon: Icons.PartnerWithUsIcon,
    routes: [
      { title: 'Channel Partner', route: '/become-a-partner' },
      // { title: 'Become Supplier', route: 'BecomeAPartnerComponent' },
    ],
  },
]

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default {
  aera5000PlueSummary,
  availableAeras,
  aera5000Summary,
  aera5000AvailableColor,
  aera5000PlusAvailableColor,
  aera5000eAvailableColor,
  footerBannerRoutes,
  hyperLocalFooterRoutes,
  weekDays,
  months
}
