import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Grid from '@mui/material/Grid'
import Icons from '../../../utils/icons'
import '../matterStream.scss'
import { useSelector } from 'react-redux'
import { ReducerModel } from '../../../reducers/reducerModel'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { InputAdornment, Select, useMediaQuery, MenuItem, ClickAwayListener } from '@mui/material'
import moment from 'moment'
import { News, NewsCategory } from '../../../constants/model'
import ProgressiveImage from '../../../components/ProgressiveImage'
import React from 'react'
import { tagMethodGTM } from '../../../utils/common'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Swiper, SwiperSlide } from 'swiper/react'

const NewsCategories = () => {
  const { 
    newsCategories,
    newsCategory
  } = useSelector(
      (store: ReducerModel) => store.home
    )
  
  const history = useHistory()

  const categoryNames = newsCategories.map((newsCategory: NewsCategory, index) => {
    return newsCategory.categoryName
  })
  const [categoryName, setCategoryName] = useState('')

  //filter category which contains news
  const newsAllCategories = React.useMemo(() => {    
    const latestNews: News[] = []
    newsCategories.map((news: NewsCategory)=> {
      news.allnews.map((newsItem: News)=> {
        latestNews.push(newsItem)
      })
    })
    
    latestNews.sort((a,b) => {          
      return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
    })
    return latestNews
  }, [newsCategories])

  //category blogs
  const categoryNews = React.useMemo(() => {    
    const newsAll : any[] = []    
    newsCategories.reduce((news1, news2) => news1.categoryName === newsCategory ? news1 : news2)
      .allnews.sort((a, b) => {        
          return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
        }).map((news)=> {
          newsAll.push(news)
        })                      
    setCategoryName(newsCategory)
    
    return newsAll
  }, [newsCategories])

  
  const [isBlogsLoaded, setIsBlogsLoaded] = useState(true)
  const [filteredBlogs, setFilteredBlogs] = useState<any[]>(categoryNews)  

  const dateOptions = ["Day", "Week", "Month"]
  const minuteOptions = ["1-5", "5-10", "10-15"]
  const sortByOptions = ["Latest First", "Oldest First"]
  const [sortBy, setSortBy] = useState('Latest First')
  const [filterByMin, setFilterByMin] = useState('')
  const [filterByDate, setFilterByDate] = useState('')
  const [displayDate, setdisplayDate] = useState(true)
  const [displayMins, setdisplayMins] = useState(true)
  const [displayFilter, setDisplayFilter] = useState(false)
  const [displayCategoryIcon, setDisplayCategoryIcon] = useState(false)
  const [displayFilterByIcon, setDisplayFilterByIcon] = useState(false)
  const [displaySortByIcon, setDisplaySortByIcon] = useState(false)
  const [sliceMore, setSliceMore] = useState(3)
  
  const isMini = useMediaQuery('(max-width:860px)');
  const isMobile = useMediaQuery('(max-width:576px)');

  useEffect(() => {            
    window.scrollTo(0, 0)   
    // //get all news
    // dispatch(homeActions.getAwards())   
    // dispatch(homeActions.getWhoWeAreLeaders())
  },[])  
  
  //filter blogs by date 
  const handleDate = (date: any) => {   
    tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Filter By", "Click", date.target.value)     
    setFilterByDate(date.target.value)    
    setdisplayDate(false)
    setIsBlogsLoaded(false)
    
    const news = newsCategories.filter((item: any)=> item.categoryName === categoryName)
      
    const filterBlogs = filterBlogsByDate(news[0].allnews, date.target.value)     

    if(sortBy === sortByOptions[0]) {
      filterBlogs.sort((a:any,b:any) => {          
        return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
      })  
    } else if(sortBy === sortByOptions[1]) {
      filterBlogs.sort((a:any,b:any) => {          
        return new Date(a.newsDate).getTime() - new Date(b.newsDate).getTime()
      })
    }

    setFilteredBlogs(filterBlogs)
    // if(filterByMin) {
    //   console.log('sort by')
    //   const combineFilteredBlogs = filterBlogsByMins(filterBlogs, filterByMin)        
    //   setFilteredBlogs(combineFilteredBlogs)
    // } 
    // else {
    //   setFilteredBlogs(filterBlogs)
    // } 
  }    
    
  //filter blogs by read time
  // const handleMins = (mins: any) => {
  //   tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Filter By", "Click", mins.target.value)
  //   setFilterByMin(mins.target.value)
  //   setdisplayMins(false)
  //   setIsBlogsLoaded(false)

  //   const news = newsCategories.filter((item: any)=> item.categoryName === categoryName)

  //   const filterBlogs = filterBlogsByMins(news[0].allnews, mins.target.value)            

  //   if(filterByDate) {
  //     const combineFilteredBlogs = filterBlogsByDate(filterBlogs, filterByDate)        
  //     setFilteredBlogs(combineFilteredBlogs)
  //   } else {
  //     setFilteredBlogs(filterBlogs)
  //   }
  // }

  // const filterBlogsByMins = (blogs: any, mins: string) => {
  //   const filterBlogs =  blogs.filter((item: any) => {
  //     if(mins === minuteOptions[0])
  //       return item.readTime >= 1 && item.readTime < 5
  //     if(mins === minuteOptions[1])
  //       return item.readTime >= 5 && item.readTime < 10
  //     if(mins === minuteOptions[2])
  //       return item.readTime >= 10 && item.readTime < 15
  //     })
  //   return filterBlogs
  // }

  const filterBlogsByDate = (blogs: any, date: string) => {        
    const filterBlogs = blogs.filter((item:any) => {
      if(date === dateOptions[0])        
        return new Date(item.newsDate) >= subtractDay()              
      if(date === dateOptions[1]) {
        return new Date(item.newsDate) >= subtractWeeks()
      }            
      if(date === dateOptions[2]) {        
        const month = subtractMonths(new Date(), 1);            
        return new Date(item.newsDate) >= month
      }                    
    })
    return filterBlogs
  }

  //to find the date of a month ago
  const subtractMonths = (date: Date, months: number) => {
    date.setMonth(date.getMonth() - months);
    date.setHours(0,0,0)
    return date;
  }

  //to find date of a week ago
  const subtractWeeks = () => {
    const now = new Date();
    now.setHours(0,0,0)
    return new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7,
    );
  }

  //one day ago
  const subtractDay = (date = new Date()) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    previous.setHours(0,0,0);
    return previous;
  }  
    
  //sort blogs by publish date
  const handleSortBy = (sortBy: any) => {    
    tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Sort By", "Click", sortBy.target.value) 
    setIsBlogsLoaded(false)       
    setSortBy(sortBy.target.value)

    let sortedBlogs : any[] = []
    if(sortBy.target.value === sortByOptions[0]) {
      sortedBlogs = filteredBlogs.sort((a,b) => {          
        return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
      })  
    } else if(sortBy.target.value === sortByOptions[1]) {
      sortedBlogs = filteredBlogs.sort((a,b) => {          
        return new Date(a.newsDate).getTime() - new Date(b.newsDate).getTime()
      })
    }        
    setFilteredBlogs([...sortedBlogs])
  }  

  const handleClearDate = () => {
    const news = newsCategories.filter((item: any)=> item.categoryName === categoryName)
    setdisplayDate(true)
    setFilterByDate('')    
    console.log('date')

    let sortedBlogs : any[] = []
    if(sortBy === sortByOptions[0]) {
      sortedBlogs = news[0].allnews.sort((a,b) => {          
        return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
      })  
    } else if(sortBy === sortByOptions[1]) {
      sortedBlogs = news[0].allnews.sort((a,b) => {          
        return new Date(a.newsDate).getTime() - new Date(b.newsDate).getTime()
      })
    }        
    setFilteredBlogs([...sortedBlogs])
    
    //setFilteredBlogs(news[0].allnews)      
    
    // else if(!displayDate) {
    //   console.log('date & mins')
    //   const filterBlogs = filterBlogsByMins(news[0].allnews, filterByMin)
    //   setFilteredBlogs(filterBlogs)
    // }
  }

  // const handleClearMins = () => {
  //   const news = newsCategories.filter((item: any)=> item.categoryName === categoryName)
  //   setdisplayMins(true)   
  //   setFilterByMin('')
  //   if(displayDate && !displayMins) {
  //     setFilteredBlogs(news[0].allnews)      
  //   } else if(!displayMins) {
  //     const filterBlogs = filterBlogsByDate(news[0].allnews, filterByDate)
  //     setFilteredBlogs(filterBlogs)
  //   }
  // }

  const ClearDateIcon = () => (
    <>
      {displayDate ? "" 
                  : <img style={displayDate ? {zIndex: '999', marginRight: '12px'} : {zIndex: '0', marginRight: '12px'}} className="pointer" src={Icons.CloseBlackIcon} alt='Caret Black Color Icon' onClick={handleClearDate}/> }
    </>    
  )

  // const ClearMinsIcon = () => (
  //   <>
  //     {displayMins ? ""
  //                 : <img style={displayMins ? {zIndex: '999', marginRight: '12px'} : {zIndex: '0', marginRight: '12px'}} className="pointer" src={Icons.CloseBlackIcon} alt='Caret Black Color Icon' onClick={handleClearMins}/> }
  //   </>    
  // )

  // const openBlog = (title: string) => {
  //   let blogTitle = title.toLowerCase().replace(/ /g,"_").replace(/\?/g, '').replace(/\-/g, "_")
  //   window.open('/blogs/' + blogTitle, '_blank')    
  // }

  //Category Blogs 
  const handleCategoryBlogs = (categoryName: any) => {    
    console.log('onclick')
    setSliceMore(3)
    setSortBy('Latest First')
    setdisplayDate(true)
    setFilterByDate('')
    setdisplayMins(true)   
    setFilterByMin('')
    setCategoryName(categoryName.target.value)
    const blogs : any[] = []
    setIsBlogsLoaded(false)
    newsCategories.filter((blog:any) => (blog.categoryName == categoryName.target.value)).map((blogCategory: NewsCategory) => {    
      blogCategory.allnews.sort((a, b) => {
          console.log(blogCategory.allnews)
          return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
        }).map((blog)=> {
          blogs.push(blog)
        })        
    }) 
    
    setFilteredBlogs(blogs)
  }

  const handleCategoryIcon = () => {
    console.log('icon')
    setDisplayCategoryIcon(true)   
  }

  const handleCategoryIconClose = () => {
    console.log('icon close')
    setDisplayCategoryIcon(false)   
  }

  const handleFilterByIcon = () => {
    console.log('icon')
    setDisplayFilterByIcon(true)   
  }

  const handleFilterByIconClose = () => {
    console.log('icon close')
    setDisplayFilterByIcon(false)   
  }

  const handleSortByIcon = () => {
    console.log('icon')
    setDisplaySortByIcon(true)   
  }

  const handleSortByIconClose = () => {
    console.log('icon close')
    setDisplaySortByIcon(false)   
  }

  const handleFilter = () => {
    setDisplayFilter(true)
  }

  const handleFilterCross = () => {    
    setDisplayFilter(false)    
    //setFilterByMin('')
    setFilterByDate('')
    //handleClearMins()
    handleClearDate()
    setSortBy('Latest First')

    const news = newsCategories.filter((item: any)=> item.categoryName === categoryName)
    let sortedBlogs : any[] = []
    
    sortedBlogs = news[0].allnews.sort((a,b) => {          
      return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
    })  

    setFilteredBlogs([...sortedBlogs])
  }

  return (
    <div className="matter-stream">
      <Header />
      <h1 className="visuallyHidden">Category Blogs</h1>        

      <div className="matter-news-content content-container">        
        
        <div className="flex al-center monument">
          <p className="category-title partnerLabelColor pointer" onClick={() => {
            history.push('/matter-stream')
            }}>
              Matter Stream               
              <img style={{ marginLeft: '14px' }} src={Icons.RightChevronIconBlack} alt='Right Caret Black Color Icon' /> 
          </p>          
          <p style={{ marginLeft: '14px' }} className="category-title partnerLabelColor pointer" onClick={() => {
            history.push('/matter-stream/news')
            }}>News
            <img style={{ marginLeft: '14px' }} src={Icons.RightChevronIconBlack} alt='Right Caret Black Color Icon' />
          </p>          
          <p className='category-title-news'>Featured</p>
        </div>

        {!isMobile && <div className={filteredBlogs.length > 0 ? "filter-options" : "filter-options-error"}>                  
            <Grid container spacing={11} style={{ marginTop: '0' }}>
              <Grid item xs={12} md={8} lg={5} style={{ paddingTop: '0' }}>                
                <div className="filters">
                  <p className="sort-by">Category</p>
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    className="monument pointer"
                    onChange={handleCategoryBlogs}      
                    onOpen={handleCategoryIcon}      
                    onClose={handleCategoryIconClose}    
                    variant="standard"
                    defaultValue={newsCategory}
                    disableUnderline           
                    startAdornment={
                      <>
                        <InputAdornment className="sort-by-icon" position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '999'}}>                            
                          {displayCategoryIcon ? <img className="caret-icon" src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' /> :
                            <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' />}
                        </InputAdornment>
                      </>
                    }  
                  >                
                    {categoryNames.map((option) => (
                      <MenuItem                    
                        key={option}
                        value={option}
                        className="dropdown-options"                        
                      >
                        <p className="black" style={{ fontSize: '14px' }}>{option}</p>
                      </MenuItem>
                    ))}
                  </Select>   
                </div>     
              </Grid>
              <Grid item xs={12} md={4} lg={7} className="sort-by-section" style={{ paddingTop: '0', paddingLeft: '0' }}> 
                <div className={isMini ? "flex js-start" : "flex js-end"}>
                  <div className="filters">
                    <p className="filter-by">Filter by:</p>                       
                    <div className="filter-dropdown">           
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        className="monument"
                        onChange={handleDate}
                        onOpen={handleFilterByIcon}      
                        onClose={handleFilterByIconClose}
                        variant="standard"
                        disableUnderline    
                        startAdornment = {
                          <>
                            <InputAdornment position="start" style={displayDate ? {zIndex: '999'} : {zIndex: '0'}}>    
                              {displayDate && <><p className='adornmentDate'>Date</p>
                                {/* <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' /> */}
                                {displayFilterByIcon ? <img src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' /> :
                                  <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' />}
                              </>
                              }                                                    
                            </InputAdornment>
                          </>
                        }
                        IconComponent={ClearDateIcon}   
                        value={filterByDate}                 
                      >                
                        {dateOptions.map((option) => (
                          <MenuItem                    
                            key={option}
                            value={option}
                            className="dropdown-options"
                          >
                            <p className="small black">1 {option}</p>                    
                          </MenuItem>
                        ))}
                      </Select>   
                      {/* <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        className="monument"
                        onChange={handleMins}                
                        variant="standard"
                        disableUnderline    
                        startAdornment={
                          <>
                            <InputAdornment position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '0'}}>    
                              {displayMins && <><p className='adornmentReadTime'>Read Time</p>
                              <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' /></>}
                            </InputAdornment>
                          </>
                        }
                        IconComponent={ClearMinsIcon}      
                        value={filterByMin}          
                      >                
                        {minuteOptions.map((option) => (
                          <MenuItem                    
                            key={option}
                            value={option}
                            className="dropdown-options"
                          >
                            <p className="small black">{option} mins</p>
                          </MenuItem>
                        ))}
                      </Select>                         */}
                    </div>                     
                  </div>                                         
                  <div className="filters ml-16">
                    <p className="sort-by">Sort by:</p>
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      style={!isMobile ? { height: "46px" } : {}}
                      className="monument"
                      onChange={handleSortBy}                
                      onOpen={handleSortByIcon}      
                      onClose={handleSortByIconClose}
                      variant="standard"
                      //defaultValue={sortByOptions[0]}
                      value={sortBy}
                      disableUnderline           
                      startAdornment={
                        <>
                          <InputAdornment className="sort-by-icon" position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '999'}}>                            
                            {/* <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' /> */}
                            {displaySortByIcon ? <img className="caret-icon" src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' /> :
                                  <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' />}
                          </InputAdornment>
                        </>
                      }  
                    >                
                      {sortByOptions.map((option) => (
                        <MenuItem                    
                          key={option}
                          value={option}
                          className="dropdown-options"
                        >
                          <p className="black" style={{ fontSize: '14px' }}>{option}</p>
                        </MenuItem>
                      ))}
                    </Select>   
                  </div>
                </div>     
              </Grid>          
            </Grid>
          </div>}

          {isMobile && <Grid container spacing={11} style={{ marginTop: '28px' }}>
          <Grid item xs={8} md={8} lg={5} style={{ paddingTop: '0' }}>                
            <div className="filters">
              <p className="sort-by">Category</p>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                className="monument"
                onChange={handleCategoryBlogs}    
                onOpen={handleCategoryIcon}      
                onClose={handleCategoryIconClose}            
                variant="standard"
                defaultValue={newsCategory}
                disableUnderline           
                startAdornment={
                  <>
                    <InputAdornment className="sort-by-icon" position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '999'}}>                            
                      {displayCategoryIcon ? <img className="caret-icon" src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> :
                        <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} />}
                    </InputAdornment>
                  </>
                }                  
              >                
                {categoryNames.map((option) => (
                  <MenuItem                    
                    key={option}
                    value={option}
                    className="dropdown-options"                                        
                  >
                    <p className="small black">{option}</p>
                  </MenuItem>
                ))}
              </Select>   
            </div>     
          </Grid>
          <Grid item xs={4} md={8} lg={5} style={{ paddingTop: '0' }}>                
            <div className="flex js-end" style={{ marginTop: '28px' }}>
              {(filterByDate || filterByMin) ? <img className="caret-icon" src={Icons.FilterIconRed} alt='Filter Icon' onClick={handleFilter}/>
                : <img className="caret-icon" src={Icons.FilterIcon} alt='Filter Icon' onClick={handleFilter}/>}  
            </div>
          </Grid>
        </Grid>}

          {isMobile && <Dialog
            //maxWidth={"md"}
            //aria-labelledby="customized-dialog-title"
            open={displayFilter}
            //className="verify-email confirmation-dialog"
            className="matter-dialog"
            fullWidth
            style={isMobile ? {top: '0', bottom: 'auto'} : {}}
          >          
            <DialogContent style={{ height: '100vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>                                  
              <div className={filteredBlogs.length > 0 ? "filter-options" : "filter-options-error"}>        
                <div className="flex js-end">                
                  <img className="caret-icon" src={Icons.CloseIconGray} alt='Filter Icon' onClick={handleFilterCross}/>
                </div>
                  <Grid container spacing={11} style={{ marginTop: '0' }}>                    
                    <Grid item xs={12} md={4} lg={7} className="sort-by-section" style={{ paddingTop: '0', paddingLeft: '0' }}>                       
                        <div className="filters">
                          <p className="filter-by">Filter by:</p>                       
                          <div className="mb-24">           
                            <Select
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              className="monument"
                              onChange={handleDate}
                              onOpen={handleFilterByIcon}      
                              onClose={handleFilterByIconClose}
                              variant="standard"
                              disableUnderline    
                              startAdornment = {
                                <>
                                  <InputAdornment position="start" style={displayDate ? {zIndex: '999'} : {zIndex: '0'}}>    
                                    {displayDate && <div className="flex js-between w-100"><p className='adornmentDate'>Date</p>
                                      {displayFilterByIcon ? <img src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> :
                                        <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} />}
                                      </div>
                                    }                                                    
                                  </InputAdornment>
                                </>
                              }
                              IconComponent={ClearDateIcon}                    
                              style={{ marginBottom: '20px'}}
                              value={filterByDate}
                            >                
                              {dateOptions.map((option) => (
                                <MenuItem                    
                                  key={option}
                                  value={option}
                                  className="dropdown-options"                                  
                                >
                                  <p className="black" style={isMobile ? {fontSize: '14px'} : {}}>1 {option}</p>                    
                                </MenuItem>
                              ))}
                            </Select>   
                            {/* <Select
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              className="monument"
                              onChange={handleMins}                
                              variant="standard"
                              disableUnderline    
                              startAdornment={
                                <>
                                  <InputAdornment position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '0'}}>    
                                    {displayMins && <div className="flex js-between w-100"><p className='adornmentReadTime'>Read Time</p>
                                    <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /></div>}
                                  </InputAdornment>
                                </>
                              }
                              IconComponent={ClearMinsIcon}      
                              value={filterByMin}  
                            >                
                              {minuteOptions.map((option) => (
                                <MenuItem                    
                                  key={option}
                                  value={option}
                                  className="dropdown-options"
                                >
                                  <p className="black">{option} mins</p>
                                </MenuItem>
                              ))}
                            </Select>                         */}
                          </div>                     
                        </div>                                         
                        <div className="filters">
                          <p className="sort-by">Sort by:</p>
                          <Select
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            className="monument"
                            style={!isMobile ? { height: "46px" } : {}}
                            onChange={handleSortBy}    
                            onOpen={handleSortByIcon}      
                            onClose={handleSortByIconClose}            
                            variant="standard"
                            //defaultValue={sortByOptions[0]}
                            value={sortBy}
                            disableUnderline           
                            startAdornment={
                              <>
                                <InputAdornment className="sort-by-icon" position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '999'}}>                            
                                <div className="flex js-end w-100">
                                  {/* <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> */}
                                  {displaySortByIcon ? <img className="caret-icon" src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> :
                                    <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} />}
                                </div>                                  
                                </InputAdornment>
                              </>
                            }  
                          >                
                            {sortByOptions.map((option) => (
                              <MenuItem                    
                                key={option}
                                value={option}
                                className="dropdown-options"
                              >
                                <p className="adornmentReadTime black" style={isMobile ? {fontSize: '14px'} : {}}>{option}</p>
                              </MenuItem>
                            ))}
                          </Select>   
                        </div>                       
                    </Grid>          
                  </Grid>
                </div>  
                <button                  
                  onClick={() => {
                    setDisplayFilter(false)
                    setSliceMore(3)
                  }}
                  className={"btn-gradiant-border large mt-24 mt-xs-16 btn-white w-100"}
                  style={{ boxShadow: 'none', marginBottom: '40px', lineHeight: '1.5', justifyContent: 'center'}}
                >
                  Show {(filterByDate || filterByMin) && filteredBlogs.length} Results
                </button>            
            </DialogContent>
        </Dialog>}        

      {/* display filtered and initial load blogs */}
      {filteredBlogs.length > 0 ?           
          <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
            {isMobile ? <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
              {(isBlogsLoaded ? categoryNews : filteredBlogs).slice(0, sliceMore).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0' }}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(item.newsURL)                                 
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {                    
                   
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.newsPublisher}</p> 
                    {/* <span className="dot ml-8"></span> */}
                    {/* <p className="date ml-8">{item.readTime} min read</p> */}
                    <p className="blog-data-title mt-8">
                      {item.newsTitle}
                    </p>
                  </div>
                  </Link>
                </Grid>
              )}  
              {((sliceMore < (isBlogsLoaded ? categoryNews : filteredBlogs).length) && isMobile) && 
                <div style={{ paddingLeft: "12px" }}>
                    <p className="more-posts primary pointer" 
                      style={{marginTop: '0px'}}
                      onClick={() => {
                        setSliceMore(sliceMore + 3)
                      }}>
                      <span className="underline pb-2">Load More</span>
                      <img
                        className="ml-4"
                        src={Icons.RightChevronIconBlue}
                        alt="Right Chevron Icon Blue"
                      />
                    </p>                  
                </div>}             
            </Grid> : <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
              {(isBlogsLoaded ? categoryNews : filteredBlogs).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', marginBottom: '40px' }}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(item.newsURL)                                 
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {                    
                   
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.newsPublisher}</p> 
                    {/* <span className="dot ml-8"></span> */}
                    {/* <p className="date ml-8">{item.readTime} min read</p> */}
                    <p className="blog-data-title mt-8">
                      {item.newsTitle}
                    </p>
                  </div>
                  </Link>
                </Grid>
              )}           
            </Grid>} 
          </div>: <>
          {/* // display recent blogs if no blogs found after filter */}
          <div className="recent-blogs">
            <p className="filter-error-msg">Opps, we couldn’t find a {categoryName} try adjusting your filter.</p>            
          </div>
          <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
          <h5 className="monument mb-16">Latest News</h5>
            {isMobile ? <Grid container spacing={{xs: 1.5, sm: 3}}>
                {newsAllCategories.slice(0,3).map((item, index)=>                 
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      window.open(item.newsURL)                    
                    }}>
                    <div className="blog-posts monument pointer" onClick={() => {
                                          
                    }
                      }>
                      <ProgressiveImage alt="Matter Blogs"                    
                        className="more-posts-blog-img" 
                        imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                      <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                      <span className="dot"></span>                     
                      <p className="date ml-8">{item.newsPublisher}</p> 
                      {/* <span className="dot ml-8"></span> */}
                      {/* <p className="date ml-8">{item.readTime} min read</p> */}
                      <p className="blog-data-title mt-8">
                        {item.newsTitle}
                      </p>
                    </div>
                    </Link>
                  </Grid>
                )}               
              </Grid> :
              <Swiper
                spaceBetween={24}
                width={300}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {newsAllCategories.slice(0,3).map((item, index)=>                 
                  <SwiperSlide>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      window.open(item.newsURL)                    
                    }}>
                    <div className="blog-posts monument pointer" onClick={() => {
                                          
                    }
                      }>
                      <ProgressiveImage alt="Matter Blogs"                    
                        className="more-posts-blog-img" 
                        imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                      <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                      <span className="dot"></span>                     
                      <p className="date ml-8">{item.newsPublisher}</p> 
                      {/* <span className="dot ml-8"></span> */}
                      {/* <p className="date ml-8">{item.readTime} min read</p> */}
                      <p className="blog-data-title mt-8">
                        {item.newsTitle}
                      </p>
                    </div>
                    </Link>
                  </SwiperSlide>
                )}
              </Swiper>
            }
            </div>
            </>
          }
      </div>        
      <Footer />
    </div>    
  )
}

export default NewsCategories
